// Import necessary dependencies and components
import React from 'react'
import { render, screen, fireEvent, waitFor } from '@testing-library/react'
import userEvent from '@testing-library/user-event'
import { BrowserRouter as Router } from 'react-router-dom'
import AccountsPage from '../AccountsPage'
import '@testing-library/jest-dom'

jest.mock('components/lib/api/apiClient', () => ({
  accounts: {
    getAccounts: jest.fn(() => Promise.resolve({ accounts: [{ key: '1', uuid: '1', name: 'Account 1', id_rsa: '123' }] })),
  },
}))

jest.mock('../AccountForm', () => () => <div data-testid="account-form" />)

describe('AccountsPage Component', () => {
  test('renders AccountsPage component', async () => {
    render(
      <Router>
        <AccountsPage />
      </Router>
    )
    await waitFor(() => screen.findByText('Account 1'))
    expect(screen.getByText('Account 1')).toBeInTheDocument()
    expect(screen.getByText('ID RSA')).toBeInTheDocument()
  })

  test('opens AccountForm when "New Account" button is clicked', async () => {
    render(
      <Router>
        <AccountsPage />
      </Router>
    )
    await waitFor(() => screen.findByText('Account 1'))
    fireEvent.click(screen.getByText('New Account'))
    await waitFor(() => screen.findByTestId('account-form'))
    expect(screen.getByTestId('account-form')).toBeInTheDocument()
  })

  test('opens AccountForm when a row is clicked', async () => {
    render(
      <Router>
        <AccountsPage />
      </Router>
    )
    await waitFor(() => screen.findByText('Account 1'))
    userEvent.click(screen.getByText('Account 1'))
    await waitFor(() => screen.findByTestId('account-form'))
    expect(screen.getByTestId('account-form')).toBeInTheDocument()
  })
})
