import React, { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import { Table, Form, Tag} from 'antd'
import Heading from 'components/common/Heading/Heading'
import UserForm from './UserForm'
import usePagination from 'components/hooks/usePagination'
import { Helmet } from 'react-helmet'
const columns = [
  { title: 'ID', dataIndex: 'key' },
  { title: 'Email', dataIndex: 'email' },
  { title: 'First Name', dataIndex: 'first_name' },
  { title: 'Last Name', dataIndex: 'last_name' },
  { title: 'Accounts', dataIndex: 'accounts', render: (_, { accounts }) => (
    <>
      {accounts.map((account) => {
        return (
          <Tag color='green' key={account.id}>
            {account.name.toUpperCase()}
          </Tag>
        )
      })}
    </>
  )},
  { title: 'Role', dataIndex: 'role' },
]
const UsersPage = () => {
  const [users, setUsers] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [form] = Form.useForm()
  const [accounts, setAccounts] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {pagination, setPagination} = usePagination()
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination
    })
  }
  const fetchData = useCallback( async () => {
    try {
      const response = await apiClient.users.getUsers()
      const response_accounts = await apiClient.accounts.getAccounts()
      setPagination((prevState) => ({
        ...prevState,
        total: response.users.length,
      }))
      setAccounts(response_accounts.accounts)
      setUsers(response.users)
    } catch (error) {
      console.log('Failed to fetch user data:', error)
    }
  }, [setPagination])

  const newUser = () => {
    setSelectedUser(null)
    form.setFieldsValue({})
    setIsModalOpen(true)
  }

  const editUser = (user) => {
    setSelectedUser(user)
    const remapUser = {
      ...user,
      account_ids: user.accounts
    }
    form.setFieldsValue(remapUser)
    setIsModalOpen(true)
  }

  useEffect(() => {
    if(!users) fetchData()
  },[users, fetchData])
  return (
    <>
      <Helmet>
        <title>PCP - Users Page</title>
      </Helmet>
      <Heading title='Users' buttons={[{
        buttonText: 'New User',
        onClick: newUser
      }]} />
      <Table
        columns={columns}
        dataSource={users}
        pagination={pagination}
        onChange={handleTableChange}
        onRow={(user) => ({
          onClick: () => editUser(user),
        })}
      />
      <UserForm
        isModalOpen={isModalOpen}
        accounts={accounts}
        setUsers={setUsers}
        setIsModalOpen={setIsModalOpen}
        selectedUser={selectedUser}
        form={form}
        users={users}
      />
    </>
  )
}

export default UsersPage