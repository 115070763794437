import { useEffect, useRef, useState } from 'react'
const searchDelay = 400
const useDebouncedParams = (searchOptions) => {
  const paramsRef = useRef()
  paramsRef.current = { searchOptions }
  const [value, setValue] = useState(paramsRef.current)
  const timeout = useRef()
  useEffect(() => {
    if(!timeout.current) {
      const currentValue = paramsRef.current
      setValue(currentValue)
      timeout.current = setTimeout(() => {
        if(paramsRef.current.searchOptions !== currentValue.searchOptions){
          setValue(paramsRef.current)
        }
        timeout.current = null
      }, [searchDelay])
    }
  }, [searchOptions])
  return value
}

export default useDebouncedParams