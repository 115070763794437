import AuthenticationEndpoint from '../AuthenticationEndpoint'

describe('AuthenticationEndpoint', () => {
  const axios = {
    post: jest.fn(),
    delete: jest.fn(),
  }
  const authentication = AuthenticationEndpoint(axios)

  afterEach(() => {
    jest.clearAllMocks()
  })

  it('should call logIn and return a token', async () => {
    const mockToken = 'mockedToken'
    axios.post.mockResolvedValue({ data: { token: mockToken } })

    const loginParams = { username: 'testuser', password: 'password' }
    const token = await authentication.logIn(loginParams)

    expect(axios.post).toHaveBeenCalledWith('/web/sessions', {
      session: loginParams,
    })
    expect(token).toBe(mockToken)
  })
})