import React, { useEffect, useState } from 'react'
import { Button, Card, Input, List, Spin } from 'antd'
import { QueryBuilderAntD } from '@react-querybuilder/antd'
import { QueryBuilder, defaultOperators } from 'react-querybuilder'
import apiClient from 'components/lib/api/apiClient'
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { useBannerActions } from 'components/context/BannerContext'
const Rules = ({template}) => {
  const [query, setQuery] = useState({ combinator: 'and', rules: [] })
  const [validations, setValidations] = useState([])
  const [ruleNames, setRuleNames] = useState([])
  const [dirtyCount, setDirtyCount] = useState(-1)
  const [variables, setVariables] = useState([{ name: 'none', label: 'none' }])
  const [selectedItem, setSelectedItem] = useState('')
  const [inputForm, setInputForm] = useState('')
  const [count, setCount] = useState(1)
  const [editRule, setEditRule] = useState(null)
  const {setBannerMessage, setBannerError } = useBannerActions()
  const newRule = () => {
    let name = `Rule ${count}`
    while (ruleNames.includes(name)){
      name = `Rule ${count + 1}`
      setCount(count + 1)
    }
    const newRule = [...ruleNames, name]
    setRuleNames(newRule)
    setDirtyCount(dirtyCount + 1)
  }

  const saveRules = async () => {
    try {
      const newValidations = validations.reduce((acc, validation) => {
        const key = Object.keys(validation)[0]
        const rules = validation[key]?.rules

        if (key !== 'null' && (rules !== null && rules.length > 0)) {
          acc[key] = validation[key]
        }
        return acc
      }, {})
      const { message } = await apiClient.templates.templateConditions(template.id, {validations: JSON.stringify(newValidations)})
      setBannerMessage(message)
      setDirtyCount(0)
    } catch (error) {
      console.error(error)
      setBannerError('Validations were not saved!')
    }
  }

  const handleRuleDelete = (ruleName) => {
    const updatedRuleNames = ruleNames.filter(name => name !== ruleName)

    const updatedValidations = validations.filter(validation => !validation[ruleName])

    setRuleNames(updatedRuleNames)
    setValidations(updatedValidations)
    setSelectedItem('')
    setDirtyCount(dirtyCount + 1)
  }

  const handleEdit = (ruleName, index) => {
    setEditRule(index)
    setInputForm(ruleName)
  }

  const handleCancel = () => {
    setEditRule(null)
    setInputForm('')
  }

  const handleEditForm = (e) => {
    setInputForm(e.target.value)
  }

  const handleRuleUpdate = async () => {
    if (ruleNames.includes(inputForm)) {
      setBannerError(`${inputForm} already exists!`)
      return
    }
    const updatedRuleNames = ruleNames.map(name => (name === selectedItem ? inputForm : name))

    const updatedValidations = validations.map(validation => {
      if (validation[selectedItem]) {
        const { [selectedItem]: value, ...rest } = validation;
        return { ...rest, [inputForm]: value };
      }
      return validation
    })

    setRuleNames(updatedRuleNames)
    setValidations(updatedValidations)
    setDirtyCount(dirtyCount + 1)
    setEditRule(null)
    setBannerMessage('Rule name has been udpated!')
  }

  const handleItemClick = (item) => {
    if(item !== selectedItem) {
      const foundValidation = validations.find(val => val[item])
      setQuery(((foundValidation && foundValidation[item]) || { combinator: 'and', rules: [] }))
      setSelectedItem(item)
    }
  }

  const handleSaveValidation = (q) => {
    const existingValidationIndex = validations.findIndex((validation) =>
      validation[selectedItem]
    )

    if (existingValidationIndex !== -1) {
      const updatedValidations = [...validations]
      updatedValidations[existingValidationIndex][selectedItem] = q
      setValidations(updatedValidations)
    } else {
      setValidations([...validations, { [selectedItem]: q }])
    }
    setDirtyCount(dirtyCount + 1)
  }

  useEffect(() => {
    if (template){
      const { optional_variables, options, required_variables } = template
      const allVars = [...required_variables, ...optional_variables]
      let fields = []
      for (const value of allVars) {
        fields = [...fields, { name: value, label: value, operators: [...defaultOperators, { name: 'regex', label: 'matches' }] }]
      }
      setVariables(fields || [])
      fields = []
      if(options?.validations){
        const keys = Object.keys(options?.validations)
        const arrayOfObjects = Object.entries(options?.validations).map(([key, value]) => {
          const transformedValue = {[key]: value}
          return transformedValue
        })
        setValidations(arrayOfObjects)
        setRuleNames(keys)
        if(keys.length > 1) setCount(keys.length)
      }
    }
  }, [template])

  if(!template) return <Spin />
  return (
    <Card>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h2>Rules</h2>
        <div className='button-group'>
          <Button className='button' onClick={newRule}>New Rule</Button>
          <Button className='button' disabled={dirtyCount === 0} onClick={saveRules}>Save {dirtyCount > 0 ? `(${dirtyCount})` : ''}</Button>
        </div>
      </div>
      <hr />
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <List
          size="small"
          width='33%'
          bordered
          className='rules-list'
          dataSource={ruleNames}
          renderItem={(item, index) => (
            <List.Item
              className={`dFlex justifyContentBetween ${selectedItem === item ? 'selected-item' : ''}`}
              onClick={() => handleItemClick(item)}
            >
              {editRule === index ? <Input name='name' defaultValue={inputForm} style={{width: '156px'}} onChange={(e) => handleEditForm(e)} /> : item}
              <div className='dFlex justifyContentBetween'>
                {
                editRule === index
                  ? <>
                    <Button style={{marginRight: '10px'}} onClick={handleRuleUpdate}><SaveOutlined /></Button>
                    <Button onClick={handleCancel}><CloseOutlined /></Button>
                  </>
                  : <>
                    <Button style={{marginRight: '10px'}} onClick={() => handleEdit(item, index)}><EditOutlined /></Button>
                    <Button danger onClick={() => handleRuleDelete(item)}><DeleteOutlined /></Button>
                  </>
                }
              </div>
            </List.Item>
        )}/>
        <QueryBuilderAntD style={{width: '66%'}}>
          <QueryBuilder fields={variables} query={query} onQueryChange={(q) => {
            handleSaveValidation(q)
            setQuery(q)
          }} />
        </QueryBuilderAntD>
      </div>
    </Card>
  )
}

export default Rules