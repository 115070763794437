const QueueEndpoint = (axios) => {
  const status = async () => {
    const { data } = await axios.get('/web/queue_status')
    return data
  }

  const clear = async (tube) => {
    await axios.post('/web/queue_status/clear', {tube: tube})
  }

  return {
    status, clear
  }
}

export default QueueEndpoint