
import React, { useState } from 'react'
import { Modal, Button, Input } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'
import { useTemplateActions } from 'components/context/TemplateContext'

const GitUpdateModal = ({openModel, handleCancel, template, setOpenUpdateModal, setTemplateId}) => {
  const {setBannerError, setBannerMessage} = useBannerActions()
  const { updateTemplate } = useTemplateActions()
  const [version, setVersion] = useState(null)
  const handleOk = async () => {
    try {
      const cleanVersion = version?.trim()
      if(template.version === cleanVersion) return setBannerError('This is the current version, please use another version')
      if(version && cleanVersion.length !== 7) return setBannerError('This is an invalid version')
      const response = await apiClient.templates.updateGitTemplate(template.id, {version})
      if(response.success){
        updateTemplate(template.id, response.template)
        setOpenUpdateModal(false)
        setTemplateId(template.id)
        setBannerMessage('Template is updating, please check back in a few minutes for updated version')
      }
    } catch (error) {
      console.error(error)
      setBannerError(error)
    }
  }
  return (
    <Modal title="Update Version" footer={null} open={openModel} onCancel={handleCancel}>
      <p>Current version: {template?.version}</p>
      <p>The latest revision will be used if no specific version is provided.</p>
      <Input style={{marginBottom: '15px'}} onChange={(e) => setVersion(e.target.value)} placeholder='Git version number (Must be 7 characters long)'/>
      <Button className='button' onClick={handleOk}>Update</Button>
    </Modal>
  )
}

export default GitUpdateModal