import React from 'react'
import { Alert, Layout } from 'antd'
import { useBannerActions, useBannerMessage } from 'components/context/BannerContext'

export const Banner = () => {
	const { Content } = Layout
  const bannerState = useBannerMessage()
  const { clearBanner } = useBannerActions()
	React.useEffect(clearBanner, [clearBanner])
  const { type, message } = bannerState

	return (
		<Content style={{marginLeft: '15px'}}>
			{ message && type && <Alert style={{marginTop: '15px'}} message={message} type={type} showIcon /> }
		</Content>
	)
}

export default Banner