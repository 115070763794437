import QueueEndpoint from '../QueueEndpoint'

describe('QueueEndpoint', () => {
  const axios = {
    get: jest.fn(),
  }
  const queue = QueueEndpoint(axios)

  afterEach(() => {
    jest.clearAllMocks()
  })

  test('status calls the API and returns the response', async () => {
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })

    const result = await queue.status()

    expect(axios.get).toHaveBeenCalledWith('/web/queue_status')
    expect(result).toEqual(mockResponse)
  })

})