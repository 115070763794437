import React from 'react'
import { render, screen, fireEvent, waitFor } from '@testing-library/react'
import '@testing-library/jest-dom'
import { MemoryRouter } from 'react-router-dom'
import NotificationDrawer from '../NotificationDrawer'
import apiClient from 'components/lib/api/apiClient'

jest.mock('components/lib/api/apiClient')

describe('NotificationDrawer component', () => {
  beforeEach(() => {
    apiClient.notification.getNotifications.mockResolvedValue({
      success: true,
      notices: [
        { messageType: 'success', message: 'Notification 1', created_at: '2023-01-01' },
        { messageType: 'warning', message: 'Notification 2', created_at: '2023-01-02' },
      ],
    })
  })

  test('renders notification tab with count', async () => {
    render(<MemoryRouter><NotificationDrawer userId={1} /></MemoryRouter>)

    const tab = screen.getByTestId('notification-tab')
    expect(tab).toBeInTheDocument()
    fireEvent.click(tab)

    await waitFor(() => {
      const drawerTitle = screen.getByText('Notification Center')
      expect(drawerTitle).toBeInTheDocument()
    })

    const notifications = screen.getAllByTestId(/^notification-item/)
    expect(notifications).toHaveLength(2)
    expect(notifications[0]).toHaveTextContent('Notification 1')
    expect(notifications[1]).toHaveTextContent('Notification 2')
  })
})

describe('NotificationDrawer', () => {
  beforeEach(() => {
    apiClient.notification.getNotifications.mockResolvedValue({
      success: true,
      notices: [],
    })
  })

  test('renders notification tab without count', async () => {
    render(<MemoryRouter><NotificationDrawer userId={1} /></MemoryRouter>)

    const tab = screen.getByTestId('notification-tab')
    expect(tab).toBeInTheDocument()
    fireEvent.click(tab)

    await waitFor(() => {
      const drawerTitle = screen.getByText('Notification Center')
      expect(drawerTitle).toBeInTheDocument()
    })

    const notifications = screen.queryAllByTestId(/^notification-item-\d+$/)
    expect(notifications.length).toBe(0)
  })
})