import { Button, List, Modal } from 'antd'
import React from 'react'

const RequestData = ({openModal, setOpenModal, selectedRequest, setSelectedRequest, restartRequest}) => {
  const excludedKeys = ['events', 'tags', 'options', 'updated_at', 'id']
  const handleCancel = () => {
    setOpenModal(false)
    setSelectedRequest(null)
  }
  const renderFields = () => {
    let hasRestartButton = false

    const fields = Object.entries(selectedRequest).map(([key, value]) => {
      if (excludedKeys.includes(key)) {
        return null
      }

      if (key === 'state' && ['delayed', 'failed'].includes(value)) {
        hasRestartButton = true
      }

      return (
        <List.Item key={key}>
          <strong>{key}:</strong> {JSON.stringify(value)}
        </List.Item>
      )
    })

    if (hasRestartButton) {
      fields.push(
        <List.Item key="restart">
          <Button type="primary" onClick={() => restartRequest(selectedRequest.id)}>Restart</Button>
        </List.Item>
      )
    }

    return fields
  }
  return (
    <Modal title='Request Data' open={openModal} onCancel={handleCancel} footer={null} width='auto'>
      <List>
        {renderFields()}
      </List>
    </Modal>
  )
}

export default RequestData