import React, { useState } from 'react'
import { Form, Input, Button, Select, Card } from 'antd'
import { titalize } from 'components/constants/titalize'
const { Option } = Select
const MultiPartForm = ({setMultiPartData, orientation, lowQuality}) => {
  const [parts, setParts] = useState([{ name: '', tag: '', kind: null}])
  const [form] = Form.useForm()

  const addPart = () => {
    setParts([...parts, { name: '', tag: ''}])
  }

  const buildOptions = (part) => {
    const orient = titalize(orientation)
    const moduleForVideo = lowQuality ? `H.264 After Codecs 720p ${orient} 40` : `H.264 After Codecs 720p ${orient}`
    const moduleForAudio = 'audio_only'
    return {
      output_module: part.kind === 'audio' ? moduleForAudio : moduleForVideo,
      render_locally: true,
      orientation: orientation,
    }
  }

  const handleFormSubmit = async () => {

    const values = await form.validateFields()
    let sources = []
    const updatedParts = values.parts.map(part => {
      const formattedTag = part.tag.replace(/\s+/g, '_')
      sources = [...sources, formattedTag]
      const mappedPayload = {
        renderer: 'render',
        name: part.name || '',
        kind: part.kind || null,
        tag: formattedTag,
        options: buildOptions(part),
        variables: [],
        part: true,
      }
      if(!part.kind) delete mappedPayload.kind
      return mappedPayload
    })
    const ffmpegPart = {renderer: 'ffmpeg', tag: 'ffmpeg', sources: sources, options: {
      overlay: true,
      orientation: orientation,
    }}
    const payload = {
        parts: updatedParts,
        ffmeg: ffmpegPart,
    }
    setMultiPartData(payload)
  }

  return (
    <Card>
      <Form form={form} layout="vertical" onChange={handleFormSubmit}>
        {parts.map((_, index) => (
          <Card key={index} style={{ marginBottom: '16px' }}>
            <Form.Item
              name={['parts', index, 'name']}
              label="Name"
              rules={[{ required: true, message: 'Please enter the part name' }]}
            >
              <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item
              name={['parts', index, 'tag']}
              label="Tag"
              rules={[{ required: true, message: 'Please enter the tag' }]}
            >
              <Input placeholder="Enter tag (no spaces)" />
            </Form.Item>
            <Form.Item
              name={['parts', index, 'kind']}
              label="Kind"
            >
              <Select
                placeholder="Select kind (not required)"
                onChange={handleFormSubmit}
              >
                <Option value="audio">Audio</Option>
                <Option value="video">Video</Option>
              </Select>
            </Form.Item>
          </Card>
        ))}
        <Form.Item>
          <Button type="dashed" onClick={addPart} block>
            Add New Part
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default MultiPartForm
