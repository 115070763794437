import React, { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import { Table } from 'antd'
import Heading from 'components/common/Heading/Heading'
import AccountForm from './AccountForm'
import usePagination from 'components/hooks/usePagination'
import { Helmet } from 'react-helmet'
const columns = [
  { title: 'Account UUID', dataIndex: 'uuid' },
  { title: 'Name', dataIndex: 'name' },
  { title: 'ID RSA', dataIndex: 'id_rsa' },
]
const AccountsPage = () => {
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {pagination, setPagination} = usePagination()
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination
    })
  }
  const fetchData = useCallback( async () => {
    try {
      const { accounts } = await apiClient.accounts.getAccounts()
      setPagination((prevState) => ({
        ...prevState,
        total: accounts.length,
      }))
      setAccounts(accounts)
    } catch (error) {
      console.log('Failed to fetch template data:', error)
    }
  }, [setPagination])

  useEffect(() => {
    if(!accounts) fetchData()
  },[accounts, fetchData])

  const newAccount = () => {
    setIsModalOpen(true)
  }

  const editAccount = (account) => {
    setSelectedAccount(account)
    setIsModalOpen(true)
  }
  return (
    <div>
      <Helmet>
        <title>PCP - Accounts Page</title>
      </Helmet>
      <Heading title='Accounts' buttons={[{
        buttonText: 'New Account',
        onClick: newAccount
      }]} />
      <Table
        columns={columns}
        dataSource={accounts}
        pagination={pagination}
        onChange={handleTableChange}
        onRow={(account) => ({
          onClick: () => editAccount(account),
        })}
      />
      <AccountForm
        isModalOpen={isModalOpen}
        accounts={accounts}
        setIsModalOpen={setIsModalOpen}
        selectedAccount={selectedAccount}
        setAccounts={setAccounts}
      />
    </div>
  )
}

export default AccountsPage