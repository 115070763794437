import Heading from 'components/common/Heading/Heading'
import React, { useCallback, useEffect, useState } from 'react'
import ComposerDetails from './ComposerDetails'
import { Card } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useTemplateActions } from 'components/context/TemplateContext'
import GitDetails from './GitDetails'
import FileDetails from './FileDetails'
import GifDetails from './GifDetails'
import { useBannerActions } from 'components/context/BannerContext'
import { Helmet } from 'react-helmet'
const TemplateBuilderPage = () => {
  const params = useParams()
  const { id } = params
  const { findTemplate } =  useTemplateActions()
  const [template, setTemplate] = useState(null)
  const { setBannerError } = useBannerActions()
  const location = useNavigate()

  const foundTemplate = useCallback(async () => {
    try {
      const temp = await findTemplate(id)
      if(temp) setTemplate(temp)
      else {
        setBannerError('Template not found')
        location('/')
      }
    } catch (error) {
      console.error(error)
    }
  }, [findTemplate, location, setBannerError, id])

  useEffect(() => {
    foundTemplate()
  },[foundTemplate])
  return (
    <>
      <Helmet>
        <title>PCP - Template Builder: {id}</title>
      </Helmet>
      <Heading
        title='New Template'
        buttons={[{
            buttonText: 'Back',
            onClick: `/`
          }]}
      />
      <div style={{display: 'flex', justifyContent: 'center'}} >
        <Card style={{width: '60%' }}>
          {template && template.source === 'composer' && <ComposerDetails template={template} />}
          {template && template.source === 'git' && <GitDetails template={template} />}
          {template && template.source === 'file' && <FileDetails template={template} />}
          {template && template.source === 'gif' && <GifDetails template={template} />}
        </Card>
      </div>
    </>
  )
}

export default TemplateBuilderPage