import React, { useState, useEffect, useCallback } from 'react'
import { Button, Card, Row, Col, Table } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { Helmet } from 'react-helmet'
const QueueStatusPage = () => {
  const [jobs, setJobs] = useState({})
  const [count, setCount] = useState(0)

  const queueClear = async (e, tube) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      await apiClient.queue.clear(tube)
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Queue',
      dataIndex: 'queueName',
      key: 'queueName',
    },
    {
      title: 'Ready',
      dataIndex: 'ready',
      key: 'ready',
    },
    {
      title: 'Reserved',
      dataIndex: 'reserved',
      key: 'reserved',
    },
    {
      title: 'Delayed',
      dataIndex: 'delayed',
      key: 'delayed',
    },
    {
      title: 'Buried',
      dataIndex: 'buried',
      key: 'buried',
    },
    {
      title: 'Clear Buried',
      dataIndex: 'actions',
      render: (_, record) => (
        <Button onClick={(e) => queueClear(e, record.tubeName)}>Clear Buried</Button>
      )
    },
  ]

  const dataSource = Object.keys(jobs).map((queueName) => ({
    key: queueName,
    queueName: queueName,
    ready: jobs[queueName]['ready'],
    reserved: jobs[queueName]['reserved'],
    delayed: jobs[queueName]['delayed'],
    buried: jobs[queueName]['buried'],
    tubeName: jobs[queueName]['tube']
  }))

  const getJobs = useCallback(async () => {
    try {
      const response = await apiClient.queue.status()
      setJobs(response.jobCount)
      setCount(response.total)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    getJobs()
  }, [getJobs])

  return (
    <div>
      <Helmet>
        <title>PCP - Queue Status Page</title>
      </Helmet>
      <Row gutter={16}>
        <Col span={24}>
          <Card title={new Date().toString()} bordered={false}>
            <div className="queue-data">
              <span className="card-description">
                Job Count: {count}
              </span>
            </div>
            <hr />
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default QueueStatusPage
