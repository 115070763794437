import { Card, Row, Radio, Divider } from 'antd'
import { CaretRightSquareFill, CardImage, ArrowRepeat, Git, FileRichtextFill, FileZip } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTemplateActions } from 'components/context/TemplateContext';
import { FileGifOutlined } from '@ant-design/icons';

const {Group, Button} = Radio
const TemplateType = () => {
  const location = useNavigate()
  const [type, setType] = useState('')
  const [source, setSource] = useState('')
  const { updateNewTemplate, resetNewTemplate } = useTemplateActions()
  const handleClick = () => {
    const kind = {
      source: source,
      kind: type,
      version: '0000001'
    }
    updateNewTemplate(kind)
    location('/templates/details')
  }

  const handleTypeClick = (e) => {
    setType(e.target.value)
    setSource('')
  }

  const handleSourceClick = (e) => {
    setSource(e.target.value)
  }

  useEffect(() => {
    resetNewTemplate()
  }, [resetNewTemplate])

  return (
    <div style={{display: 'flex', justifyContent: 'center'}} >
      <Card>
        <h5 style={{ marginBottom: '28px' }}>What type of Template?</h5>
        <Row style={{display: 'flex', justifyContent: 'space-around'}} >
          <Group buttonStyle="solid" onChange={handleTypeClick} style={{display: 'flex'}}>
            <Button className='img-thumbnail icon-container text-center' value="video">
              <CaretRightSquareFill size={75} /><br />
              <p className="lead">Video</p>
            </Button>
            <Button className='img-thumbnail icon-container text-center' value="image">
              <CardImage size={75} /><br />
              <p className="lead">Image</p>
            </Button>
            <Button className='img-thumbnail icon-container text-center' value="gif">
              <ArrowRepeat size={75} /><br />
              <p className="lead">GIF</p>
            </Button>
          </Group>
        </Row>
        <Divider />
        <h5 style={{ marginBottom: '28px' }}>Choose Template Source</h5>
        <Row style={{display: 'flex', justifyContent: 'space-around', marginTop: '30px'}} >
          <Group buttonStyle="solid" onChange={handleSourceClick} style={{display: 'flex'}}>
            <Button disabled={type !== 'video' && type !=='image'} className='img-thumbnail icon-container text-center' value="git">
              <Git size={75} /><br />
              <p className="lead">Git</p>
            </Button>
            <Button disabled={type !== 'video' && type !=='image'} className='img-thumbnail icon-container text-center' value="file">
              <FileZip size={75} /><br />
              <p className="lead">File</p>
            </Button>
            <Button disabled={type !=='image'} className='img-thumbnail icon-container text-center' value="composer">
              <FileRichtextFill size={75} /><br />
              <p className="lead">Composer</p>
            </Button>
            <Button disabled={type !== 'gif'} className='img-thumbnail icon-container text-center' value="gif">
              <FileGifOutlined style={{fontSize: '75px'}} /><br />
              <p className="lead">GIF</p>
            </Button>
          </Group>
        </Row>
        <Divider />
        <div>
          <Button disabled={type === '' || source === ''} style={{float: 'right'}} className='button' onClick={handleClick}>Next</Button>
        </div>
      </Card>
    </div>
  )
}

export default TemplateType