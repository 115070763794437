import React, { useCallback, useEffect, useState } from 'react'
import Heading from 'components/common/Heading/Heading'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Card, Input, Button, Divider, notification, Skeleton, List } from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { useBannerActions } from 'components/context/BannerContext'
import { CopyOutlined } from '@ant-design/icons'
import DefaultValueModal from './DefaultValueModal'
import { useTemplateActions } from 'components/context/TemplateContext'
import DupeModal from './DupeModal'
import Rules from './Rules'
import GitUpdateModal from './GitUpdateModal'
import useCable from 'components/hooks/useCable'
import Actioncable from 'actioncable'
import EditableAccountName from './EditableAccountName'
import EditableTemplateName from './EditableTemplateName'
import { useProfile } from 'components/context/ProfileContext'
import apiClient from 'components/lib/api/apiClient'
import { Helmet } from 'react-helmet'

const EditTemplatePage = () => {
  const [api, contextHolder] = notification.useNotification()
  const ws = useCable()
  const params = useParams()
  const { id } = params
  const [openDupModel, setOpenDupModal] = useState(false)
  const [openUpdateModel, setOpenUpdateModal] = useState(false)
  const [openDefaultModel, setOpenDefaultModal] = useState(false)
  const [variable, setVariable] = useState(null)
  const {setBannerMessage, setBannerError} = useBannerActions()
  const { findTemplate } =  useTemplateActions()
  const [template, setTemplate] = useState(null)
  const [gifList, setGifList] = useState(null)
  const [templateId, setTemplateId] = useState(null)
  const [accountName, setAccountName] = useState(null)
  const [templateName, setTemplateName] = useState(null)
  const { profile } = useProfile()
  const location = useNavigate()

  const foundTemplate = useCallback(async () => {
    try {
      const temp = await findTemplate(id)
      if(temp) {
        if(temp.source === 'gif') {
          const response = await apiClient.templates.getChildren(id)
          if(response.success && response.templates.length > 0) setGifList(response.templates)
        }
        setTemplate(temp)
        setAccountName(temp.account)
        setTemplateName(temp.name)
      }
      else {
        setBannerError('Template not found')
        location('/')
      }
    } catch (error) {
      console.error(error)
    }
  }, [findTemplate, location, setBannerError, id])
  const openNotification = useCallback(() => {
    api['success']({
      message: 'Update',
      description: 'Template version has completed updating!',
      duration: 0,
      placement: 'bottomLeft',
    })
  }, [api])
  useEffect(() => {
    foundTemplate()
  },[foundTemplate])

  const handleCancel = () => {
    setOpenDefaultModal(false)
    setOpenDupModal(false)
    setOpenUpdateModal(false)
    setVariable(null)
  }
  const defaultModal = (openModal, varibale) => {
    setOpenDefaultModal(openModal)
    setVariable(varibale)
  }

  const handleVariable = () => {
    location(`/templates/${id}/variables`)
  }
  const renderButtons = () => {
    const buttons = [
      {
      buttonText: 'Back',
      onClick: `/templates/${id}`
      },
      {
        buttonText: 'Update Template',
        onClick: () => setOpenUpdateModal(true)
      }
    ]
    if(template?.source === 'composer'){
      return [...buttons, {
          buttonText: 'Duplicate Template',
          onClick: () => setOpenDupModal(true)
        }
      ]
    } else if (template?.source === 'git') {
      return buttons
    } else if(template?.source === 'gif') {
      return [{
        buttonText: 'Back',
        onClick: `/templates/${id}`
      }, {
        buttonText: 'Edit GIF',
        onClick: `/templates/${id}/builder?redirect=/templates/${id}/edit`
      }]
    } else {
      return [{
        buttonText: 'Back',
        onClick: `/templates/${id}`
      }]
    }
  }

  const handleSave = (name, type = 'acount') => {
    if(type === 'acount') setAccountName(name)
    else setTemplateName(name)
  }

  useEffect(() => {
    if(!templateId) return
    const cable = Actioncable.createConsumer(ws)
    const templateUpdaterChannel = cable.subscriptions.create(
      { channel: 'TemplateUpdaterChannel', templateId },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Connected to Action Cable')
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Disconnected from Action Cable')
        },
        received(data) {
          setTemplate(data.template)
          setTemplateId(null)
          setBannerMessage('Template has updated!')
          openNotification()
        },
      }
    )

    return () => {
      templateUpdaterChannel.unsubscribe()
    }
  }, [templateId, ws, openNotification, setBannerMessage])

  if(!template) return <Skeleton />

  return (
    <>
      <Helmet>
        <title>PCP - Edit Template: {id}</title>
      </Helmet>
      {contextHolder}
      <Heading
        title={'Edit Template "' + (templateName || 'Loading name')+ '"'}
        buttons={renderButtons()}
      />
      <Row gutter={16}>
        <Col span={12}>
          <Card style={{marginBottom: '15px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <h2>Template Variables</h2>
              {!['composer', 'gif'].includes(template.source) && <Button size='large' className='button' onClick={handleVariable}>Edit Variables</Button>}
            </div>
            <div>
              <Row>
                {template.required_variables &&
                  template.required_variables.map((variable, index) => (
                    <Col key={index} style={{marginBottom: '15px'}} span={24}>
                      <Input size="large" placeholder={variable} />
                    </Col>
                  ))
                }
              </Row>
              {template.optional_variables &&
                template.optional_variables.map((variable, index) => (<Row key={index} style={{marginBottom: '15px'}}>
                    <Col span={18}>
                      <Input size="large" placeholder={variable} value={template.options.defaults && template.options.defaults[variable]} />
                    </Col>
                    <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Button size='large' onClick={() => defaultModal(true, variable)}>Edit Default Value</Button>
                    </Col>
                  </Row>
                ))
              }
            </div>
          </Card>
          {gifList && <Row>
            <Card style={{width: '100%'}}>
              <h2>Composer List</h2>
              <List
                size="small"
                bordered
                dataSource={gifList}
                renderItem={(item) => <List.Item className='cursor-pointer list' onClick={() => location(`/templates/${item.id}/composer?redirect=${id}`)}>{item.name}</List.Item>}
              />
            </Card>
          </Row>}
        </Col>
        <Col span={12}>
          <Row gutter={16} style={{marginBottom: '15px'}}>
            <Col span={24}>
              <Card>
                <h2>Template Details</h2>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <span style={{marginRight: '10px'}}>ID:</span>
                  <Input disabled value={id}/>
                  <CopyToClipboard text={id}
                    onCopy={() => setBannerMessage(`${id} has been copied to clipboard`)}>
                    <CopyOutlined style={{
                      borderTop: '1px solid grey',
                      borderRight: '1px solid grey',
                      borderBottom: '1px solid grey',
                      padding: '10px',
                      borderRadius: '5px'}}
                    />
                  </CopyToClipboard>
                </div>
                <Divider />
                <h5 style={{ marginRight: '10px', fontSize: '1.5em'}}>Details:</h5>
                <ul>
                  {template.source === 'composer' && (<>
                    <li>Dimensions: {template.parts[0]?.options['dimensions']}</li>
                    <li>File Type: {template.kind}</li>
                  </>)}
                  {template.source === 'git' && (<>
                    <li>Version: {templateId ? <span className='loading'>Updating version</span> : template.version}</li>
                    <li>File Type: {template.kind}</li>
                  </>)}
                  {profile?.role === 'admin' ? <EditableAccountName templateId={id} accountName={accountName}  onSave={handleSave} /> : <li>Account: {accountName}</li>}
                  {profile?.role === 'admin' ? <EditableTemplateName templateId={id} templateName={templateName} onSave={handleSave} /> : <li>Name: {templateName}</li>}
                </ul>

              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Rules template={template} />
            </Col>
          </Row>
        </Col>
      </Row>
      <DefaultValueModal
        openDefaultModel={openDefaultModel}
        handleCancel={handleCancel}
        template={template}
        variable={variable}
      />
      <DupeModal
        openModel={openDupModel}
        handleCancel={handleCancel}
        template={template}
      />
      <GitUpdateModal
        openModel={openUpdateModel}
        setOpenUpdateModal={setOpenUpdateModal}
        handleCancel={handleCancel}
        template={template}
        setTemplateId={setTemplateId}
      />
    </>
  )
}

export default EditTemplatePage