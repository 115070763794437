import { Button, Card, Divider, Form, Input, Select } from 'antd'
import Heading from 'components/common/Heading/Heading'
import React, { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import AccountForm from 'components/pages/AccountsPage/AccountForm'
import { useTemplateActions, useTemplates } from 'components/context/TemplateContext'
import { useBannerActions } from 'components/context/BannerContext'
import { useNavigate } from 'react-router-dom'
import { useProfile } from 'components/context/ProfileContext'
import { Helmet } from 'react-helmet'
const TemplateDetails = () => {
  const [form] = Form.useForm()
  const { profile } = useProfile()
  const [accounts, setAccounts] = useState([])
  const [account, setAccount] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const { newTemplate } = useTemplates()
  const { addTemplate, updateNewTemplate } = useTemplateActions()
  const { setBannerError } = useBannerActions()
  const location = useNavigate()
  const fetchData = useCallback( async () => {
    try {
      const { accounts } = await apiClient.accounts.getAccounts()
      const mappedAccounts = accounts.map((account) => ({
        value: account.id,
        label: account.name
      }))
      setAccounts(mappedAccounts)
    } catch (error) {
      console.log('Failed to fetch template data:', error)
    }
  }, [])

  useEffect(() => {
    fetchData()
  },[fetchData])

  useEffect(() => {
    if(account.length === 0) return
    setAccounts((prevAccount) => [...prevAccount, {value: account[0]?.id, label: account[0]?.name}])
    form.setFieldsValue({ account_id: account[0]?.id })
    setOpenModal(false)
  },[account, form])

  const handleNext = async () => {
    try {
      const values = await form.validateFields()
      await updateNewTemplate(values)
    } catch (error) {

    }
  }

  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/\s+/g, '-')
    form.setFieldsValue({ tag: newValue })
  }

  const saveTemplate = useCallback(async () => {
    try {
      const response = await apiClient.templates.createTemplate(newTemplate.template)
      if (response.success) {
        addTemplate(response.template)
        location(`/templates/${response.template.id}/builder`)
      } else {
        setBannerError(response.errors[0])
      }
    } catch (error) {
      console.log('Failed to save template data:', error)
    }
  }, [addTemplate, location, newTemplate.template, setBannerError])

  useEffect(() => {
    const {name, tag, account_id} = newTemplate.template

    if(name && tag && account_id) saveTemplate()
  }, [saveTemplate, newTemplate.template])

  return (
    <>
      <Helmet>
        <title>PCP - Template Details</title>
      </Helmet>
      <Heading
        title='New Template'
        buttons={[{
            buttonText: 'Back',
            onClick: `/`
          }]}
      />
      <div style={{display: 'flex', justifyContent: 'center'}} >
        <Card style={{width: '60%' }}>
          <h5 style={{ marginBottom: '28px' }}>Template Details</h5>
          <Form form={form} layout="vertical" >
            <Form.Item name="name" required>
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="tag" required>
              <Input onChange={handleInputChange} placeholder="Tag" />
            </Form.Item>
            <div style={{display: 'flex'}}>
              <Form.Item name="account_id">
                <Select
                  required
                  showSearch
                  style={{
                    width: 200,
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={accounts.length ? accounts : profile?.accounts.map((account) => ({value: account.id, label: account.name}))}
                />
              </Form.Item>
              {profile?.role === 'admin' && (
                <span>
                  &nbsp; - or - &nbsp;
                  <Button onClick={() => setOpenModal(true)}>New Account</Button>
                </span>
              )}
            </div>
          </Form>
          <Divider />
          <div className='right'>
            <Button className='button' onClick={handleNext}>Next</Button>
          </div>
          <AccountForm
            isModalOpen={openModal}
            accounts={account}
            setIsModalOpen={setOpenModal}
            selectedAccount={null}
            setAccounts={setAccount}
          />
        </Card>
      </div>
  </>
  )
}

export default TemplateDetails