import React from 'react'
import { Form, Input, Button, message, Layout, Card } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import apiClient from 'components/lib/api/apiClient'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import logo from 'components/sinch-logo.png'
import { Content } from 'antd/es/layout/layout'
const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const handleSubmit = async (values) => {
    let token = searchParams.get('token')
    token = token?.replace(/[{}]/g, '')
    setLoading(true)
    try {
      await apiClient.authentication.resetPassword({ token, password: values.password })
      message.success('Password reset successfully!')
      navigate('/')
    } catch (error) {
      message.error('Failed to reset password. Try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout className="login-form-layout">
      <Helmet>
        <title>PCP - Rest Password</title>
      </Helmet>
      <Card>
        <Content style={{marginBottom: '5%', textAlign: 'center'}}>
          <img style={{width: '175px'}} src={logo} alt="Logo" />
        </Content>
        <Form onFinish={handleSubmit} className="login-form">
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} className="login-form-button">
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
      </Layout>
  )
}

export default ResetPasswordPage
