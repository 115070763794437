import React from 'react';
import { render, screen, act } from '@testing-library/react';
import { MemoryRouter } from 'react-router-dom';
import { BannerProvider } from 'components/context/BannerContext';
import { ProfileProvider } from 'components/context/ProfileContext';
import cookies from 'components/util/cookies/url/cookies';
import Nav from './Nav';
import apiClient from 'components/lib/api/apiClient';
import '@testing-library/jest-dom';

jest.mock('components/common/Notification/NotificationDrawer', () => ({
  __esModule: true,
  default: jest.fn(() => null),
}));

jest.mock('components/lib/api/apiClient');
jest.mock('components/util/cookies/url/cookies');

const renderWithProviders = async (component) => {
  // eslint-disable-next-line testing-library/no-unnecessary-act
  await act(async () => {
    render(
      <MemoryRouter>
        <ProfileProvider>
          <BannerProvider>
            {component}
          </BannerProvider>
        </ProfileProvider>
      </MemoryRouter>
    );
  });
};

const someToken = 'some-token';
const mockProfile = { role: 'admin' };

describe('Nav component', () => {
  beforeEach(() => {
    cookies.authToken.set(someToken);

    apiClient.authentication = {
      logOut: jest.fn(() => {
        return Promise.resolve();
      }),
    };

    // Mock getUser to return the mock profile
    apiClient.users.getUser = jest.fn().mockResolvedValue(mockProfile);
  });

  afterEach(() => {
    jest.clearAllMocks();
    jest.restoreAllMocks();
  });

  it('renders the component with logo and links', async () => {
    await renderWithProviders(<Nav />);

    expect(screen.getByAltText('Sinch Logo')).toBeInTheDocument();
    expect(screen.getByText('Request Reporter')).toBeInTheDocument();
  });
});
