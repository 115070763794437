const NotificationEndpoint = (axios) => {
  const getNotifications = async (userId) => {
    const { data } = await axios.get(`/web/notifications?userId=${userId}`)
    return data
  }

  const deleteNotification = async (notificationId) => {
    const { data } = await axios.delete(`/web/notifications/${notificationId}`)
    return data
  }

  return {
    getNotifications,
    deleteNotification,
  }
}

export default NotificationEndpoint