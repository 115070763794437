import TemplatesEndpoint from '../TemplatesEndpoint'

describe('TemplatesEndpoint', () => {
  const http = {
    get: jest.fn(),
    post: jest.fn(),
    patch: jest.fn(),
    delete: jest.fn(),
  }
  const templates = TemplatesEndpoint(http)

  afterEach(() => {
    jest.clearAllMocks()
  })

  test('getTemplates calls the API and returns the response', async () => {
    const mockResponse = {}
    http.get.mockResolvedValue({ data: mockResponse })

    const searchOptions = {
      page: 1,
      query: 'your_query_here',
      sortBy: 'your_sortBy_here',
      sortDirection: 'asc',
      statuses: ['your_statuses_here'],
      pageSize: 10,
    }
    const result = await templates.getTemplates(searchOptions)

    expect(http.get).toHaveBeenCalledWith(
      `/web/templates?page=1&query=your_query_here&sortBy=your_sortBy_here&sortDirection=asc&statuses=your_statuses_here&pageSize=10`
    )
    expect(result).toEqual(mockResponse)
  })

  test('updateTemplate calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const params = {}
    const mockResponse = {}
    http.patch.mockResolvedValue({ data: mockResponse })

    const result = await templates.updateTemplate(templateId, params)

    expect(http.patch).toHaveBeenCalledWith(`/web/templates/${templateId}`, params)
    expect(result).toEqual(mockResponse)
  })


  test('createTemplate calls the API and returns the response', async () => {
    const params = {}
    const mockResponse = {}
    http.post.mockResolvedValue({ data: mockResponse })

    const result = await templates.createTemplate(params)

    expect(http.post).toHaveBeenCalledWith('/web/templates', params)
    expect(result).toEqual(mockResponse)
  })

  test('deleteTemplate calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const mockResponse = {}
    http.delete.mockResolvedValue({ data: mockResponse })

    const result = await templates.deleteTemplate(templateId)

    expect(http.delete).toHaveBeenCalledWith(`/web/templates/${templateId}`)
    expect(result).toEqual(mockResponse)
  })

  test('archiveTemplate calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const mockResponse = {}
    http.patch.mockResolvedValue({ data: mockResponse })

    const result = await templates.archiveTemplate(templateId)

    expect(http.patch).toHaveBeenCalledWith(`/web/templates/${templateId}/archive`)
    expect(result).toEqual(mockResponse)
  })

  test('getTemplate calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const mockResponse = {}
    http.get.mockResolvedValue({ data: mockResponse })

    const result = await templates.getTemplate(templateId)

    expect(http.get).toHaveBeenCalledWith(`/web/templates/${templateId}`)
    expect(result).toEqual(mockResponse)
  })

  test('updateTemplateDefaults calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const params = {}
    const mockResponse = {}
    http.patch.mockResolvedValue({ data: mockResponse })

    const result = await templates.updateTemplateDefaults(templateId, params)

    expect(http.patch).toHaveBeenCalledWith(`/web/templates/${templateId}/create_or_edit_template_defaults`, params)
    expect(result).toEqual(mockResponse)
  })

  test('templateConditions calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const params = {}
    const mockResponse = {}
    http.patch.mockResolvedValue({ data: mockResponse })

    const result = await templates.templateConditions(templateId, params)

    expect(http.patch).toHaveBeenCalledWith(`/web/templates/${templateId}/create_or_edit_conditionals`, params)
    expect(result).toEqual(mockResponse)
  })

  test('duplicateTemplate calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const params = {}
    const mockResponse = {}
    http.post.mockResolvedValue({ data: mockResponse })

    const result = await templates.duplicateTemplate(templateId, params)

    expect(http.post).toHaveBeenCalledWith(`/web/templates/${templateId}/duplicate`, params)
    expect(result).toEqual(mockResponse)
  })

  test('gitValidation calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const params = {}
    const mockResponse = {}
    http.post.mockResolvedValue({ data: mockResponse })

    const result = await templates.gitValidation(templateId, params)

    expect(http.post).toHaveBeenCalledWith(`/web/templates/${templateId}/git_validations`, params)
    expect(result).toEqual(mockResponse)
  })

  test('fileValidation calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const mockResponse = {}
    http.post.mockResolvedValue({ data: mockResponse })

    const { data } = await templates.fileValidation(templateId)

    expect(http.post).toHaveBeenCalledWith(`/web/templates/${templateId}/file_validations`, {})
    expect(data).toEqual(mockResponse)
  })

  test('getValidatedFile calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const mockResponse = {}
    http.get.mockResolvedValue({ data: mockResponse })

    const { data } = await templates.getValidatedFile(templateId)

    expect(http.get).toHaveBeenCalledWith(`/web/templates/${templateId}/file_validations`)
    expect(data).toEqual(mockResponse)
  })

  test('validating calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const eventType = 'your_event_type_here'
    const mockResponse = {}
    http.get.mockResolvedValue({ data: mockResponse })

    const result = await templates.validating(templateId, eventType)

    expect(http.get).toHaveBeenCalledWith(`/web/templates/${templateId}/events?type=${eventType}&return_cached_data=1`)
    expect(result).toEqual(mockResponse)
  })

  test('presignedUrl calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const params = {}
    const mockResponse = {}
    http.post.mockResolvedValue({ data: mockResponse })

    const result = await templates.presignedUrl(templateId, params)

    expect(http.post).toHaveBeenCalledWith(`/web/templates/${templateId}/presigned_urls`, params)
    expect(result).toEqual(mockResponse)
  })

  test('searchTemplates calls the API and returns the response', async () => {
    const searchTerm = 'your_search_term_here'
    const mockResponse = {}
    http.get.mockResolvedValue({ data: mockResponse })

    const result = await templates.searchTemplates(searchTerm)

    expect(http.get).toHaveBeenCalledWith(`/web/templates?template_search=${searchTerm}`)
    expect(result).toEqual(mockResponse)
  })

  test('updateGitTemplate calls the API and returns the response', async () => {
    const templateId = 'your_template_id_here'
    const mockResponse = {}
    http.post.mockResolvedValue({ data: mockResponse })

    const result = await templates.updateGitTemplate(templateId)

    expect(http.post).toHaveBeenCalledWith(`/web/templates/${templateId}/template_updates`, {})
    expect(result).toEqual(mockResponse)
  })
})