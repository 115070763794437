import { Form, Select } from 'antd'
import React from 'react'

const PartForm = ({files, destination, setValid, setPart}) => {
  const [form] = Form.useForm()
  const handleFinish = () => {
    try {
      const values = form.getFieldsValue()
      const validate = Object.values(values).some((value) => value === undefined)
      if(!validate){
        setPart(values)
        setValid(true)
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <h5>Set Parts details</h5>
      <Form form={form} onFieldsChange={handleFinish}>
        <Form.Item name="source">
          <Select placeholder="Choose file source" options={files} />
        </Form.Item>
        <Form.Item name="destination">
          <Select placeholder="Choose Destination" options={destination} />
        </Form.Item>
        <Form.Item name="script">
          <Select placeholder="Choose Script file" options={files} />
        </Form.Item>
        <Form.Item name={['options', 'orientation']}>
          <Select placeholder="Choose orientation" options={[{label: 'Portrait', value: 'portrait'}, {label: 'Landscape', value: 'landscape'}]} />
        </Form.Item>
      </Form>
    </>
  )
}

export default PartForm