import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input, Divider, Button, Checkbox } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import Validation from './Validation'
import PartForm from './PartForm'
import useDestination from 'components/hooks/useDestination'
import { useNavigate } from 'react-router-dom'
import { useBannerActions } from 'components/context/BannerContext'
import { useTemplateActions } from 'components/context/TemplateContext'
import MultiPartForm from './MultiPartForm'

const GitDetails = ({template}) => {
  const [form] = Form.useForm()
  const [valid, setValid] = useState(false)
  const [validating, setValidating] = useState(false)
  const [files, setFiles] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [part, setPart] = useState(null)
  const [required, setRequired] = useState(false)
  const [lowQuality, setLowQuality] = useState(false)
  const [multiPartData, setMultiPartData] = useState(null)
  const [createdPart, setCreated] = useState(false)
  const location = useNavigate()
  const {setBannerMessage} = useBannerActions()
  const { destination } = useDestination(template)
  const {updateNewPart, updateTemplate} = useTemplateActions()
  const handleNext = async () => {
    if(multiPartData){
      let dbParts = []
      for (const newPart of multiPartData.parts) {
        const options = {
          ...newPart.options,
        }
        const payload = {
          ...part,
          ...newPart,
          options,
          source: {path: `{{source_root}}/${part.source}}`, version: '1'},
          script: {path: `{{source_root}}/${part.script}`, version: '1'},
        }
        delete payload.destination
        dbParts = [...dbParts, payload]
      }
      const finalPayload = {
        config: [
            dbParts,
            multiPartData.ffmeg
          ]
      }
      try {
        const response = await apiClient.templates.updateTemplate(template.id, { options: finalPayload })
        if(response.success){
          const configureTemplate = await apiClient.templates.configureTemplate(template.id)
          if(configureTemplate.success){
            setBannerMessage('Part(s) has been created!')
            updateTemplate(template.id, configureTemplate.template)
            location(`/templates/${template.id}/variables`)
          }
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        const response = await apiClient.parts.createPart(template.id, part)
        if(response.success){
          setBannerMessage('Part has been created!')
          updateNewPart(template.id, response.part)
          location(`/templates/${template.id}/variables`)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const validateGit = async () => {
    const values = await form.validateFields()
    try {
      const response = await apiClient.templates.gitValidation(template.id, {template: {...values}})
      if(response.success){
        setDisabled(true)
        setValidating(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkCacheFiles = useCallback(async() => {
    try {
      const result = await apiClient.templates.validating(template.id, 'git_validation')
      if(result.cached_data){
        const arrayOfObjects = result.cached_data?.map((string) => ({
          label: string,
          value: string,
        }))
        setFiles(arrayOfObjects)
        setDisabled(true)
        setValidating(false)
        setBannerMessage('Template already validated!')
      }
    } catch (error) {

    }
  }, [template.id, setBannerMessage])

  useEffect(() => {
    if(createdPart) location(`/templates/${template.id}`)
  }, [createdPart, location, template.id])

  useEffect(() => {
    checkCacheFiles()
  }, [checkCacheFiles])

  return (
    <>
      <h5 style={{ marginBottom: '28px' }}>Git Details</h5>
      <Form onFinish={validateGit} layout="vertical" form={form}>
        <Form.Item
          label="Repository Server"
          name="repo_server"
          rules={[
            {
              required: true,
              message: 'Please input Repository Server!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Repo Account Name"
          name="repo_account"
          rules={[
            {
              required: true,
              message: 'Please input Repo Account Name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Repo Project Name"
          name="repo_name"
          rules={[
            {
              required: true,
              message: 'Please input Repo Project Name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Repo Branch"
          name="repo_branch"
          rules={[
            {
              required: true,
              message: 'Please input Repo Branch!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Revision"
          name="version"
          rules={[
            {
              required: false,
              message: 'Please input Revision!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button disabled={disabled} style={{float: 'right'}} className='button' htmlType="submit">
            Validate Repo Settings
          </Button>
        </Form.Item>
      </Form>
      {validating && <Validation setCreated={setCreated} setFiles={setFiles} setValidating={setValidating} template={template} eventType='git_validation'/>}
      {files && <PartForm files={files} destination={destination} setValid={setValid} setPart={setPart} />}
      {valid && template.kind === 'video' && <Checkbox
        onChange={() => (setRequired(!required))}
      >
        Multiparts required?
      </Checkbox>}
      {required && <Checkbox
        onChange={() => (setLowQuality(!lowQuality))}
      >
        Lower video quality?
      </Checkbox>}
      {required && <MultiPartForm setMultiPartData={setMultiPartData} orientation={part.options.orientation} lowQuality={lowQuality} />}
      <Divider />
      <div className='right'>
        <Button disabled={!valid} className='button' onClick={handleNext}>Next</Button>
      </div>
    </>
  )
}

export default GitDetails