import NotificationEndpoint from '../NotificationEndpoint'

describe('NotificationEndpoint', () => {
  const axios = {
    get: jest.fn(),
    delete: jest.fn(),
  }
  const notification = NotificationEndpoint(axios)

  afterEach(() => {
    jest.clearAllMocks()
  })

  test('getNotifications calls the API and returns the response', async () => {
    const userId = 1
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })

    const result = await notification.getNotifications(userId)

    expect(axios.get).toHaveBeenCalledWith(`/web/notifications?userId=${userId}`)
    expect(result).toEqual(mockResponse)
  })

  test('deleteNotification calls the API and returns the response', async () => {
    const notificationId = 1
    const mockResponse = {}
    axios.delete.mockResolvedValue({ data: mockResponse })

    const result = await notification.deleteNotification(notificationId)

    expect(axios.delete).toHaveBeenCalledWith(`/web/notifications/${notificationId}`)
    expect(result).toEqual(mockResponse)
  })
})