import React, { useEffect, useState } from 'react'
import Actioncable from 'actioncable'
import { Divider } from 'antd'
import useCable from 'components/hooks/useCable'
import { useProfile } from 'components/context/ProfileContext'
const RequestRate = () => {
  const [rate, setRate] = useState({completion: 0.0, creation: 0.0})
  const { profile } = useProfile()
  const ws = useCable()
  useEffect(() => {
    const cable = Actioncable.createConsumer(ws)
    const requestChannel = cable.subscriptions.create(
      { channel: 'RequestRateChannel' },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Connected to Action Cable')
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Disconnected from Action Cable')
        },
        received(data) {
          setRate(data.rate)
        },
      }
    )

    return () => {
      requestChannel.unsubscribe()
    }
  }, [ws])
  if(profile?.role !== 'admin') return null
  return (
    <div>
      <Divider />
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <label className='nav-link' style={{marginRight: '15px'}}>Creation: {rate.creation} </label>
        <label className='nav-link'>Completion: {rate.completion}</label>
      </div>
    </div>
  )
}

export default RequestRate