import React from 'react'
import useProvidedContext from '../hooks/useProvidedContext'


export const BannerValueContext = React.createContext()
export const BannerActionContext = React.createContext()

const defaultClearDelay = 3000

export const BannerProvider = ({
  bannerClearDelay = defaultClearDelay,
  children,
}) => {
  const [bannerState, setBannerState] = React.useState({})
  const bannerTimeout = React.useRef()

  const actions = React.useMemo(() => {
    const clearBanner = () => setBannerState({})
    const setBanner = ({ message, type }) => {
      setBannerState({ message, type })
      if(bannerTimeout.current) {
        clearTimeout(bannerTimeout.current)
        bannerTimeout.current = null
      }

      if(bannerClearDelay) {
        bannerTimeout.current = setTimeout(() => {
          clearBanner()
          bannerTimeout.current = null
        }, bannerClearDelay)
      }
    }
    return {
      setBannerMessage: (message) => setBanner({message, type: 'success'}),
      setBannerError: (message) => setBanner({message, type: 'error'}),
      setBannerWarning: (message) => setBanner({message, type: 'warning'}),
      setBannerNotice: (message) => setBanner({message, type: 'info'}),
      clearBanner,
    }
  }, [bannerClearDelay])

  return (
    <BannerActionContext.Provider value={actions}>
      <BannerValueContext.Provider value={bannerState}>
        {children}
      </BannerValueContext.Provider>
    </BannerActionContext.Provider>
  )
}

export const useBannerMessage = () => {
  return useProvidedContext('BannerProvider', BannerValueContext)
}

export const useBannerActions = () => {
  return useProvidedContext('BannerProvider', BannerActionContext)
}