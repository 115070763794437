import useProvidedContext from 'components/hooks/useProvidedContext'
import React, { createContext, useState, useMemo } from 'react'
import apiClient from 'components/lib/api/apiClient'
export const TemplateContext = createContext()
export const TemplateActionContext = React.createContext()
const defaultNew = {
  template: {
    id: '',
    account_id: '',
    required_variables: [],
    tag: '',
    kind: '',
    source: '',
    version: '1',
    name: '',
    repo_server: '',
    repo_account: '',
    repo_name: '',
    repo_branch: '',
  }}
export const TemplateProvider = ({ children }) => {
  const [templates, setTemplates] = useState([])
  const [newTemplate, setNewTemplate] = useState(defaultNew)

  const actions = useMemo(() => {
    const findTemplate = async (template_id) => {
      try {
        const response = await apiClient.templates.getTemplate(template_id)
        if(response.success){
          return response.template
        } else {
          return null
        }
      } catch (error) {
        console.log(error)
        return null
      }
    }

    const addTemplate = (newTemplate) => {
      setTemplates(prevTemplates => [...prevTemplates, newTemplate])
    }

    const updateTemplate = (templateId, updatedData) => {
      const updatedTemplates = templates.map(template => {
        if (template.id === templateId) {
          const formated = { ...template, ...updatedData }
          return formated
        }
        return template
      })

      setTemplates(updatedTemplates)
    }

    const updateNewTemplate = (updatedTemplate) => {
      setNewTemplate((prevState) => ({
        ...prevState,
        template: {
          ...prevState.template,
          ...updatedTemplate,
        },
      }))
    }

    const updateNewPart = (templateId, part) => {
      const updatedTemplates = templates.map(template => {
        if (template.id === templateId) {
          const formated = { ...template, parts: Array.isArray(part) ? part : [part]}
          return formated
        }
        return template
      })
      setTemplates(updatedTemplates)
    }

    const updatePart = (templateId, partId, updatedPartData) => {
      const updatedTemplates = templates.map((template) => {
        if (template.id === templateId) {
          const updatedParts = template.parts.map((part) => {
            if (part.id === partId) {
              return {
                ...part,
                ...updatedPartData,
              }
            }
            return part
          })

          return {
            ...template,
            parts: updatedParts,
          }
        }
        return template
      })
      setTemplates(updatedTemplates)
    }

    const updateParent = async (parentId) => {
      try {
        const childTemplates = await apiClient.templates.getChildren(parentId)
        if(childTemplates.success){
          const childRequiredVariables = childTemplates.templates.map((childTemplate) => {
            return childTemplate.required_variables || []
          })
          const combinedRequiredVariables = childRequiredVariables.flat()
          await apiClient.templates.updateTemplate(parentId, {
            required_variables: combinedRequiredVariables,
          })
        }
      } catch (error) {
        console.error("Error updating parent template:", error)
      }
    }

    const resetNewTemplate = () => setNewTemplate(defaultNew)
    return {
      addTemplate,
      findTemplate,
      updateTemplate,
      updateNewTemplate,
      updateNewPart,
      updatePart,
      updateParent,
      resetNewTemplate,
    }
  }, [templates])

  return (
    <TemplateActionContext.Provider value={actions}>
      <TemplateContext.Provider value={{newTemplate}}>
        {children}
      </TemplateContext.Provider>
    </TemplateActionContext.Provider>
  )
}

export const useTemplates = () => {
  return useProvidedContext('TemplateProvider', TemplateContext)
}

export const useTemplateActions = () => {
  return useProvidedContext('TemplateProvider', TemplateActionContext)
}
