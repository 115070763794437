import React, { useState, useEffect, useCallback } from 'react'
import { Select, Button } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'
const { Option } = Select

const EditableAccountName = ({ templateId, accountName, onSave }) => {
  const [isEditing, setEditing] = useState(false)
  const [selectedAccountId, setSelectedAccountId] = useState(null)
  const [accounts, setAccounts] = useState([])
  const {setBannerMessage, setBannerError} = useBannerActions()
  const handleEditClick = () => {
    setEditing(true)
  }

  const handleSaveClick = async () => {
    try {
      const response = await apiClient.templates.updateTemplate(templateId, {account_id: selectedAccountId})
      if(response.success){
        const selectedAccount = accounts.find((account) => account.id === selectedAccountId)
        onSave(selectedAccount.name)
        setEditing(false)
        setBannerMessage('Name has been updated!')
      } else {
        setBannerError(response.errors[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAccounts = useCallback(async () => {
    try {
      const response = await apiClient.accounts.getAccounts()
      const account = response.accounts.find((account) => account.name === accountName)
      setSelectedAccountId(account.id)
      setAccounts(response.accounts)
    } catch (error) {
      console.log('Failed to fetch user data:', error)
    }
  }, [accountName])

  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  return (
    <li>
      {isEditing ? (
        <>
          <Select
            style={{ width: '200px' }}
            defaultValue={selectedAccountId}
            onChange={(value) => setSelectedAccountId(value)}
          >
            {accounts.map((account) => (
              <Option key={account.id} value={account.id}>
                {account.name}
              </Option>
            ))}
          </Select>
          <Button type="link" onClick={handleSaveClick}>
            Save
          </Button>
        </>
      ) : (
        <>
          <span>Account: {accountName}</span>
          <Button type='link' onClick={handleEditClick}>Edit</Button>
        </>
      )}
    </li>
  )
}

export default EditableAccountName
