import React from 'react'
import { render, screen } from '@testing-library/react'
import '@testing-library/jest-dom'
import LandingPage from './LandingPage'
import { useProfile } from 'components/context/ProfileContext'
import { useSearch, useSearchActions } from 'components/context/SearchContext'
import useLandingTemplates from 'components/hooks/useLandingTemplates'
import useLocation from 'components/hooks/useLocation'
import { MemoryRouter } from 'react-router-dom'

jest.mock('components/context/ProfileContext')
jest.mock('components/context/SearchContext')
jest.mock('components/hooks/useLandingTemplates')
jest.mock('components/hooks/useLocation')

const mockTemplates = [
  { key: 1, id: 1, name: 'Template 1', status: 'Active' },
  { key: 2, id: 2, name: 'Template 2', status: 'Archive' },
]

const mockProfile = { role: 'admin' }

const mockSearchParams = {
  page: 1,
  query: '',
  sortBy: '',
  sortDirection: 'asc',
  statuses: ['Active'],
  pageSize: 10,
}

const mockSearchActions = {
  setPage: jest.fn(),
  setQuery: jest.fn(),
  setStatuses: jest.fn(),
  setSize: jest.fn(),
}

const mockLocation = jest.fn()
const mockReload = jest.fn()

beforeEach(() => {
  useProfile.mockReturnValue({ profile: mockProfile, isLoading: false })
  useSearch.mockReturnValue({ searchParams: mockSearchParams })
  useSearchActions.mockReturnValue(mockSearchActions)
  useLandingTemplates.mockReturnValue({
    templates: mockTemplates,
    pagination: {},
    reload: mockReload,
  })
  useLocation.mockReturnValue(mockLocation)
})

test('renders LandingPage with templates', async () => {
  render(
    <MemoryRouter>
      <LandingPage />
    </MemoryRouter>
  )
  expect(screen.getByText('Templates')).toBeInTheDocument()
  expect(screen.getByText('New Template')).toBeInTheDocument()
  expect(screen.getByText('ID')).toBeInTheDocument()
  expect(screen.getByText('Name')).toBeInTheDocument()
  expect(screen.getByText('Tag')).toBeInTheDocument()
  expect(screen.getByText('Type')).toBeInTheDocument()
  expect(screen.getByText('Source')).toBeInTheDocument()
  expect(screen.getByText('Account')).toBeInTheDocument()
  expect(screen.getByText('Status')).toBeInTheDocument()
  expect(screen.getByText('Actions')).toBeInTheDocument()
  expect(screen.getByText('Template 1')).toBeInTheDocument()
  expect(screen.getByText('Template 2')).toBeInTheDocument()

  const archiveButtons = screen.getAllByText('Archive')
  expect(archiveButtons).toHaveLength(3)

  const deleteButtons = screen.getAllByText('Delete')
  expect(deleteButtons).toHaveLength(2)
})