import React from 'react'
import { Form, Input, Button, message, Layout, Card } from 'antd'
import { useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import { Helmet } from 'react-helmet'
import { Content } from 'antd/es/layout/layout'
import logo from 'components/sinch-logo.png'
import { useNavigate } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      await apiClient.authentication.forgotPassword({ email: values.email })
      message.success('Password reset link sent! Check your email.')
    } catch (error) {
      message.error('Failed to send password reset link. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout className="login-form-layout">
      <Helmet>
        <title>PCP - Forgot Password</title>
      </Helmet>
      <Card>
        <Content style={{marginBottom: '5%', textAlign: 'center'}}>
          <img style={{width: '175px'}} src={logo} alt="Logo" />
        </Content>
        <Form onFinish={handleSubmit} className="login-form">
          <Form.Item
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Email'/>
          </Form.Item>
          <Form.Item>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button style={{marginRight: '10px'}} onClick={() => navigate('/')}>
                Back
              </Button>
              <Button type="primary" htmlType="submit" loading={loading} className="login-form-button">
                Send Reset Link
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  )
}

export default ForgotPasswordPage
