import React from 'react'
import apiClient from 'components/lib/api/apiClient'
import { Button, Popconfirm, Row, Select, Space, Table, Input, Skeleton } from 'antd'
import Heading from 'components/common/Heading/Heading'
import { useProfile } from 'components/context/ProfileContext'
import useLandingTemplates from 'components/hooks/useLandingTemplates'
import { useSearch, useSearchActions } from 'components/context/SearchContext'
import useLocation from 'components/hooks/useLocation'
import { Helmet } from 'react-helmet'

const LandingPage = () => {
  const { Search } = Input
  const { profile, isLoading } = useProfile()
  const allStatuses = [{ value: 'All', label: 'All' }, { value: 'Active', label: 'Active' }, { value: 'Archive', label: 'Archive' }]
  const { searchParams } = useSearch()
  const { setPage, setQuery, setStatuses, setSize } = useSearchActions()
  const { templates, pagination, setPagination, reload } = useLandingTemplates(searchParams)
  const location = useLocation()

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination
    if(searchParams.page !== current) setPage(current)
    if(searchParams.pageSize !== pageSize) setSize(pageSize)
    setPagination({
      ...pagination
    })
  }

  const archive = async (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      const { success } = await apiClient.templates.archiveTemplate(id)
      if(success) {
        reload()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteTemplate = async (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      const { success } = await apiClient.templates.deleteTemplate(id)
      if(success) {
        reload()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const navigatTo = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    location(`/templates/${id}/requests`)
  }

  const columns = [
    { title: 'ID', dataIndex: 'id' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Tag', dataIndex: 'tag' },
    { title: 'Type', dataIndex: 'kind' },
    { title: 'Source', dataIndex: 'source' },
    { title: 'Account', dataIndex: 'account' },
    { title: 'Status', dataIndex: 'status' },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {!record.parent_id && <Popconfirm
            title="Are you sure you want to proceed?"
            onConfirm={(e) => archive(e, record.id)}
            okText="Yes"
            cancelText="No"
          >
          <Button onClick={(e) => e.stopPropagation()}>Archive</Button>
        </Popconfirm>}

        {profile?.role === 'admin' && <Popconfirm
          title="Are you sure you want to proceed?"
          onConfirm={(e) => deleteTemplate(e, record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button onClick={(e) => e.stopPropagation()}>Delete</Button>
        </Popconfirm>}
        {profile?.role === 'admin' &&
          <Button onClick={(e) => navigatTo(e, record.id)}>Requests</Button>
        }
        </Space>
      )
    },
  ]

  const handleSearchStatues = (statuses) => {
    const stats = statuses.includes('All') ? ['Active', 'Archive'] : statuses
    setStatuses(stats)
  }

  const setSearchTerm = (query) => {
    setQuery(query)
  }

  if(isLoading) return <Skeleton />

  return (
    <>
      <Helmet>
        <title>PCP - Landing Page</title>
      </Helmet>
      <Heading title='Templates' buttons={[{
        buttonText: 'New Template',
        onClick: () => location('/templates/new')
      }]} />
      <Row style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}>
        <Select
          style={{width: '15%'}}
          mode='multiple'
          allowClear
          onChange={handleSearchStatues}
          maxTagCount='responsive'
          options={allStatuses}
          value={searchParams.statuses}
        />
        <Search
          style={{width: '25%'}}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Search for templates'
          defaultValue={searchParams.query}
        />
      </Row>
      <Table
        columns={columns}
        dataSource={templates}
        pagination={pagination}
        onChange={handleTableChange}
        onRow={(record) => {
          return {
            onClick: (e) => location(`/templates/${record.id}`, e.metaKey)
          }
        }}
      />
    </>
  )
}

export default LandingPage