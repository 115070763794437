import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { Button, Row, Select, Space, Table } from 'antd'
import { useSearch, useSearchActions } from 'components/context/SearchContext'
import { Link } from 'react-router-dom'
import Heading from 'components/common/Heading/Heading'
import useRequests from 'components/hooks/useRequests'
import { Helmet } from 'react-helmet'
const allStateObject = [
  { value: 'All', label: 'All' },
  { value: 'new', label: 'New' },
  { value: 'started', label: 'Started' },
  { value: 'delayed', label: 'Delayed' },
  { value: 'failed', label: 'Failed' },
  { value: 'done', label: 'Done' },
]
const allStates = ['new', 'started', 'delayed', 'failed', 'done']
const RequestPage = () => {
  const navigate = useNavigate()
  const { searchParams } = useSearch()
  const params = useParams()
  const { id } = params
  const { setPage, setSize, setStates } = useSearchActions()
  const { requests, pagination, setPagination } = useRequests(searchParams, id)

  const goTo = (requestId) => {
    navigate(`/request-reporter?requestIds=${requestId}`)
  }

  const handleSearchStates = (states) => {
    const status = states.includes('All') ? allStates : states
    setStates(status)
  }

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination
    if(searchParams.page !== current) setPage(current)
    if(searchParams.pageSize !== pageSize) setSize(pageSize)
    setPagination({
      ...pagination
    })
  }

  const columns = [
    { title: 'Created At', dataIndex: 'created_at' },
    { title: 'ID', dataIndex: 'id' },
    { title: 'State', dataIndex: 'state' },
    {
      title: 'Asset',
      dataIndex: 'asset',
      render: (_, record) => {
        if(record.asset_url){
          return (
            record.state === 'done' && <Space size="middle">
              <Link target="_blank" to={record.asset_url}>View</Link>
            </Space>
          )
        }
        return null
      }
    },
    {
      title: 'Report',
      dataIndex: 'details',
      render: (_, record) => {
        return (
          <Button
            type='link'
            onClick={() => goTo(record.id)}>
              View
          </Button>
        )
      }
    },
  ]

  return (
    <>
      <Helmet>
        <title>PCP - Request Page</title>
      </Helmet>
      <Heading title='Requests' buttons={[{
        buttonText: 'Back',
        onClick: () => navigate('/')
      }]} />
      <Row style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}>
        <Select
          style={{width: '15%'}}
          mode='multiple'
          allowClear
          onChange={handleSearchStates}
          maxTagCount='responsive'
          options={allStateObject}
          value={searchParams.states}
        />
      </Row>
      <Table
        columns={columns}
        dataSource={requests}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  )
}

export default RequestPage