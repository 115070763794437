import React, { useCallback, useEffect, useState } from 'react'
import Heading from 'components/common/Heading/Heading'
import apiClient from 'components/lib/api/apiClient'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Card, Input, Button, Divider, Form, Skeleton } from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { useBannerActions } from 'components/context/BannerContext'
import { CopyOutlined } from '@ant-design/icons'
import Request from './Request'
import Billing from './Billing'
import DataValidator from './DataValidator'
import { useTemplateActions } from 'components/context/TemplateContext'
import useCable from 'components/hooks/useCable'
import Actioncable from 'actioncable'
import { Helmet } from 'react-helmet'

const ShowTemplate = () => {
  const params = useParams()
  const [template, setTemplate] = useState(null)
  const ws = useCable()
  const [form] = Form.useForm()
  const [requiredVars, setRequiredVars] = useState({})
  const [optionalVars, setOptionalVars] = useState({})
  const { setBannerMessage, setBannerError } = useBannerActions()
  const { id } = params
  const { findTemplate } =  useTemplateActions()
  const location = useNavigate()
  const [requestId, setRequests] = useState(null)
  const [reloadImage, setReloadImage] = useState(true)
  const handleFormSubmit = async () => {
    try {
      await form.validateFields()
      const rValues = {
        required_variables: requiredVars,
        optional_variables: optionalVars,
      }
      const response = await apiClient.requests.createRequest(id, rValues)
      if(response.success){
        setRequests(response.request.id)
        setBannerMessage('Request created. Please wait for rendering to complete.')
      } else {
        setBannerError(response.errors[0])
      }
      form.resetFields()
    } catch (error) {
      console.log('Form validation error:', error)
    }
  }

  const handleInputChange = (variableType, name, value) => {
    if (variableType === 'required') {
      setRequiredVars({ ...requiredVars, [name]: value })
    } else if (variableType === 'optional') {
      setOptionalVars({ ...optionalVars, [name]: value })
    }
  }

  const calculateThumbSize = (dimensions) => {
    const [width, height] = dimensions?.split('x') || ['225px', '225px']
    if (parseFloat(width) > parseFloat(height)) return '225px'
    else return `${Math.round((230 / parseFloat(height)) * parseFloat(width))}px`
  }

  const foundTemplate = useCallback(async () => {
    try {
      const temp = await findTemplate(id)
      if(temp) {
        if(temp.parts.length === 0) location(`/templates/${id}/builder`)
        else setTemplate(temp)
      }
      else {
        setBannerError('Template not found')
        location('/')
      }
    } catch (error) {
      console.error(error)
    }
  }, [findTemplate, location, setBannerError, id])

  const reloadThumbnail = useCallback(() => {
    setReloadImage(true)
    setTimeout(() => {
      setReloadImage(false)
  }, 15000)
  }, [])

  useEffect(() => {
    if(!template) foundTemplate()
    else setReloadImage(false)
  },[template, foundTemplate])

  useEffect(() => {
    if(!id) return
    const cable = Actioncable.createConsumer(ws)
    const templateUpdaterChannel = cable.subscriptions.create(
      { channel: 'TemplateUpdaterChannel', templateId: id },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Connected to Action Cable')
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Disconnected from Action Cable')
        },
        received() {
          reloadThumbnail()
        },
      }
    )

    return () => {
      templateUpdaterChannel.unsubscribe()
    }
  }, [id, ws, reloadThumbnail])

  if(!template) return <Skeleton />

  return (
    <>
      <Helmet>
        <title>PCP - Show Page: {id}</title>
      </Helmet>
      <Heading
        title={'Template "' + template?.name + '"'}
        buttons={template?.source !== 'composer' ?
        [{
          buttonText: 'Edit Template Details',
          onClick: `/templates/${id}/edit`
        }] : [{
          buttonText: 'Edit Template Details',
          onClick: `/templates/${id}/edit`
        },
        {
          buttonText: 'Show Composer',
          onClick: `/templates/${id}/composer`
        }]
      } />
      <Row gutter={16}>
        <Col span={10}>
          <Card style={{marginBottom: '15px'}}>
            <h2>Create Request</h2>
            <div>
              <Form form={form} onSubmit={handleFormSubmit}>
                {template && (template.required_variables) &&
                  template.required_variables.map((variable) => (
                    <Form.Item
                      key={variable}
                      name={variable}
                      rules={[
                        { required: true, message: 'This field is required.' },
                      ]}
                    >
                      <Input size="large" placeholder={variable} onChange={(e) => handleInputChange('required', variable, e.target.value)}/>
                    </Form.Item>
                  ))
                }
                {template && (template.optional_variables) &&
                  template.optional_variables.map((variable) => (
                    <Form.Item key={variable} name={variable}>
                      <Input size="large" placeholder={`${variable} Optional`} onChange={(e) => handleInputChange('optional', variable, e.target.value)} />
                    </Form.Item>
                  ))
                }
                <Button className='button' style={{float: 'right'}} onClick={handleFormSubmit}>Send Test Request</Button>
              </Form>
            </div>
          </Card>
          {template.data_validators.length !== 0 && <DataValidator dataValidators={template.data_validators} />}
        </Col>
        <Col span={14}>
          <Row style={{marginBottom: '15px'}}>
            {template?.thumbnail_url && <Col span={8}>
              <Card style={{display: 'flex', justifyContent: 'center' }}>
              {reloadImage ? <span className='loading'>Updating thumbnail</span> : (
                <img
                  src={template.thumbnail_url}
                  alt="thumbnail"
                  style={{ width: calculateThumbSize(template.parts[0]?.options['dimensions']) }}
                />
              )}
              </Card>
            </Col>}
            <Col span={template?.thumbnail_url ? 16 : 24}>
              <Card>
                <h2>Template Details</h2>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <span style={{marginRight: '10px'}}>ID:</span>
                  <Input disabled value={id}/>
                  <CopyToClipboard text={id}
                    onCopy={() => setBannerMessage(`${id} has been copied to clipboard`)}>
                    <CopyOutlined style={{
                      borderTop: '1px solid grey',
                      borderRight: '1px solid grey',
                      borderBottom: '1px solid grey',
                      padding: '10px',
                      borderRadius: '5px'}}
                    />
                  </CopyToClipboard>
                </div>
                {template && template.source === 'composer' && (<>
                  <Divider />
                  <h5 style={{ marginRight: '10px', fontSize: '1.5em'}}>Composer Details:</h5>
                  <ul>
                    <li>Dimensions: {template.parts[0]?.options['dimensions']}</li>
                    <li>File Type: {template.kind}</li>
                  </ul>
                </>)}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Card>
                <h2>Requests</h2>
                <Request template_id={id} requestId={requestId} />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Card>
                <h2>Billings</h2>
                <Billing template_id={id} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default ShowTemplate