import React from 'react'
import { TemplateContext } from '../context/TemplateContext'
import { ProfileContext } from '../context/ProfileContext'
import { SearchContext } from '../context/SearchContext'
import { BannerProvider } from '../context/BannerContext'
import { createMockProfile, createMockSearch, createMockTemplate, createMockAccount } from '../testUtils/createMockInfo'
import { AccountContext } from 'components/context/AccountContext'

// Mock the ApplicationProvider
const MockApplicationProvider = ({
  bannerState = {},
  searchParams = createMockSearch,
  profileInfo = createMockProfile,
  template = createMockTemplate,
  account = createMockAccount,
  children
}) => {
  return (
    <AccountContext.Provider value={account}>
      <TemplateContext.Provider value={template}>
        <ProfileContext.Provider value={profileInfo}>
          <SearchContext.Provider value={searchParams}>
            <BannerProvider>
                {children}
            </BannerProvider>
          </SearchContext.Provider>
        </ProfileContext.Provider>
      </TemplateContext.Provider>
    </AccountContext.Provider>
  )
}

export default MockApplicationProvider
