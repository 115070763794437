import PartsEndpoint from '../PartsEndpoint'

describe('PartsEndpoint', () => {
  const axios = {
    post: jest.fn(),
    patch: jest.fn(),
  }
  const parts = PartsEndpoint(axios)

  afterEach(() => {
    jest.clearAllMocks()
  })

  test('createPart calls the API and returns the response', async () => {
    const template_id = 1
    const mockParams = {}
    const mockResponse = {}
    axios.post.mockResolvedValue({ data: mockResponse })

    const result = await parts.createPart(template_id, mockParams)

    expect(axios.post).toHaveBeenCalledWith(`/web/templates/${template_id}/parts`, mockParams)
    expect(result).toEqual(mockResponse)
  })

  test('updatePart calls the API and returns the response', async () => {
    const template_id = 1
    const part_id = 2
    const mockParams = {}
    const mockResponse = {}
    axios.patch.mockResolvedValue({ data: mockResponse })

    const result = await parts.updatePart(template_id, part_id, mockParams)

    expect(axios.patch).toHaveBeenCalledWith(`/web/templates/${template_id}/parts/${part_id}`, mockParams)
    expect(result).toEqual(mockResponse)
  })

})