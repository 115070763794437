import React, { useEffect } from 'react'
import { Modal, Form, Input } from 'antd'
import { useBannerActions } from 'components/context/BannerContext'
import apiClient from 'components/lib/api/apiClient'
const AccountForm = ({isModalOpen, accounts, setIsModalOpen, selectedAccount, setAccounts}) => {
  const {setBannerMessage, setBannerError } = useBannerActions()
  const [form] = Form.useForm()
  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      if(selectedAccount){
        const response = await apiClient.accounts.updateAccount(selectedAccount._id, values)
        const updatedAccounts = accounts.map((account) => {
          if (account._id === selectedAccount._id) {
            return response.account
          }
          return account
        })
        setAccounts(updatedAccounts)
      } else {
        const { account } = await apiClient.accounts.createAccount(values)
        setAccounts(prevUsers => [...prevUsers, account])
      }
      setBannerMessage(selectedAccount ? 'Account has been updated!' : 'New account has been created!')
      form.resetFields()
      setIsModalOpen(false)
    } catch (error) {
      if(!selectedAccount){
        setBannerError('Failed to create new account')
        console.error('Failed to create account', error);
      }
      else {
        setBannerError('Failed to update account')
        console.error('Failed to update account', error);
      }
    }
  }

  useEffect(() => {
    if(selectedAccount) form.setFieldsValue(selectedAccount)
  }, [selectedAccount, form])

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }
  return (
    <Modal title="New Account" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical" >
        <Form.Item name="name" required>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="id_rsa" required>
          <Input placeholder="ID RSA" />
        </Form.Item>
        <Form.Item name="uuid" required>
          <Input placeholder="Account UUID" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AccountForm