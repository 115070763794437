import React from 'react'
import { Modal, Form, Input } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useTemplateActions } from 'components/context/TemplateContext'
import { useBannerActions } from 'components/context/BannerContext'

const DefaultValueModal = ({openDefaultModel, handleCancel, template, variable}) => {
  const [form] = Form.useForm()
  const { updateTemplate } = useTemplateActions()
  const {setBannerMessage, setBannerError} = useBannerActions()
  const handleOk = async () => {
    try {
      const default_values = await form.validateFields()
      const response = await apiClient.templates.updateTemplateDefaults(template.id, {default_values, template})
      if(response.success){
        updateTemplate(template.id, response.template)
        setBannerMessage(`Default has been set for variable: ${variable}`)
        handleCancel()
      } else setBannerError(`Default was not set for variable: ${variable}`)
    } catch (error) {
      console.error(error)
      setBannerError(`Default was not set for variable: ${variable}`)
    }
  }
  return (
    <Modal title="New or Edit Default Value" open={openDefaultModel} onOk={handleOk} onCancel={handleCancel}>
      <p>NOTE: To delete simply remove value and submit</p>
      <Form form={form} layout="vertical" >
        <Form.Item name={variable}>
          <Input placeholder="Default value" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DefaultValueModal