import React, { useState } from 'react'
import { Button, Input } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'

const EditableTemplateName = ({ templateId, templateName, onSave }) => {
  const [isEditing, setEditing] = useState(false)
  const {setBannerMessage, setBannerError} = useBannerActions()
  const [newName, setName] = useState(templateName)
  const handleEditClick = () => {
    setEditing(true)
  }

  const handleSaveClick = async () => {
    try {
      const response = await apiClient.templates.updateTemplate(templateId, {name: newName})
      if(response.success){
        onSave(newName, 'template')
        setEditing(false)
        setBannerMessage('Name has been updated!')
      } else {
        setBannerError(response.errors[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <li>
      {isEditing ? (
        <div style={{display: 'flex', width: '50%'}}>
          <Input value={newName} onChange={(e) => setName(e.target.value)}/>
          <Button type="link" onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      ) : (
        <>
          <span>Name: {templateName}</span>
          <Button type='link' onClick={handleEditClick}>Edit</Button>
        </>
      )}
    </li>
  )
}

export default EditableTemplateName
