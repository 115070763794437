import { useState } from 'react'

const usePagination = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  return {
    pagination,
    setPagination
  }
}

export default usePagination