import { Badge } from 'antd'
import { BellOutlined } from '@ant-design/icons';
import React from 'react'

const NotificationTab = ({ onClick, count }) => {
  return (
    <Badge data-testid='notification-tab' dot={count} onClick={onClick}>
      <BellOutlined
        style={{
          fontSize: 16,
          color: 'rgba(0, 0, 0, 0.55)'
        }}
      />
    </Badge>
  )
}

export default NotificationTab
