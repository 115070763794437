const PartsEndpoint = (axios) => {
  const createPart = async (template_id, params) => {
    const { data } = await axios.post(`/web/templates/${template_id}/parts`, params)
    return data
  }

  const updatePart = async (template_id, part_id, params) => {
    const { data } = await axios.patch(`/web/templates/${template_id}/parts/${part_id}`, params)
    return data
  }

  return {
    createPart,
    updatePart,
  }
}

export default PartsEndpoint