import React, { useState, useEffect, useCallback, useRef } from 'react'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'
import useCable from 'components/hooks/useCable'
import Actioncable from 'actioncable'
const Validation = ({template, setValidating, setFiles, eventType = 'git_validation', setCreated = null}) => {
  const [messages, setMessages] = useState(['Connecting...'])
  const intervalId = useRef(null)
  const {setBannerError, setBannerMessage} = useBannerActions()
  const ws = useCable()
  const cable = Actioncable.createConsumer(ws)

  const fetchData = useCallback(async () => {
    try {
      const result = await apiClient.templates.validating(template.id, eventType)
      if (result.errors.length) {
        setBannerError(result.errors)
      } else {
        const events = result.events
        const failedEvent = events.find((event) => event.state === 'failed')
        if (failedEvent) {
          setBannerError(failedEvent.tags[0])
          setValidating(false)
          clearInterval(intervalId.current)
          return
        }

        const newMessages = events.map((event) => event.tags[0])
        if(newMessages.length){
          setMessages((prevState) => [...new Set([...prevState, ...newMessages])])
        }

        let cachedFilesEvent
        if(eventType === 'git_validation'){
          if(result.parts_created){
            setValidating(false)
            setCreated(result.parts_created)
            setBannerMessage('Template has been completed!')
            return
          } else cachedFilesEvent = true
        } else {
          cachedFilesEvent = events.find((event) => event.state === 'files_cached') || result.cached_data
        }
        if (cachedFilesEvent) {
          const arrayOfObjects = result.cached_data?.map((string) => ({
            label: string,
            value: string,
          }))
          if(eventType === 'git_validation') setCreated(result.parts_created)
          setFiles(arrayOfObjects)
          setValidating(false)
          clearInterval(intervalId.current)
          setBannerMessage('Validated!')
        }
      }
    } catch (error) {
      setBannerError('Something went wrong, check console log')
      console.log(error)
      clearInterval(intervalId.current)
    }
  }, [intervalId, eventType, setBannerError, setBannerMessage, template.id, setFiles, setValidating, setCreated])

  useEffect(() => {
    if(eventType !== 'git_validation') return
    const eventChannel = cable.subscriptions.create(
      { channel: 'EventChannel', templateId: template.id },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Connected to Action Cable for git event')
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Disconnected to Action Cable for git event')
        },
        received(data) {
          const { state, tags } = data.event
          setMessages((prevState) => [...new Set([...prevState, tags[0]])])
          if(state === 'failed') {
            setBannerError(tags[0])
            setValidating(false)
            return
          }
          if(state === 'archive_uploaded') fetchData()
        },
      }
    )

    return () => {
      eventChannel.unsubscribe()
    }
  }, [cable.subscriptions, template.id, eventType, fetchData, setBannerError, setValidating])

  useEffect(() => {
    if(eventType === 'git_validation') return
    if(intervalId.current) return
    if (!intervalId.current) {
      const id = setInterval(fetchData, 3000)
      intervalId.current = id
    }
  }, [fetchData, intervalId, eventType])


  return (
    <div>
      <h4><span className='loading'>Validating</span></h4>
      <ul>
        {messages.map((message, idx) => (
          <li key={idx}>{message}</li>
        ))}
      </ul>
    </div>
  )
}

export default Validation
