import RequestsEndpoint from '../RequestsEndpoint'

describe('RequestsEndpoint', () => {
  const axios = {
    get: jest.fn(),
    post: jest.fn(),
  }
  const requests = RequestsEndpoint(axios)

  afterEach(() => {
    jest.clearAllMocks()
  })

  test('search calls the API and returns the response', async () => {
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })

    const ids = 'your_search_ids_here'
    const result = await requests.search(ids)

    expect(axios.get).toHaveBeenCalledWith(`/web/search?search=${ids}`)
    expect(result).toEqual(mockResponse)
  })

  test('getRequests calls the API and returns the response', async () => {
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })
    const template_id = 'your_template_id_here'
    const searchOptions = {
      template_id,
      page: 1,
      query: 'your_query_here',
      sortBy: 'your_sortBy_here',
      sortDirection: 'asc',
      states: ['your_statuses_here'],
      pageSize: 10,
    }
    const result = await requests.getRequests(searchOptions)

    expect(axios.get).toHaveBeenCalledWith(`/web/templates/${template_id}/requests?active_requests=true&page=1&query=your_query_here&sortBy=your_sortBy_here&sortDirection=asc&states=your_statuses_here&pageSize=10`)
    expect(result).toEqual(mockResponse)
  })

  test('getTestRequests calls the API and returns the response', async () => {
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })

    const templateId = 'your_template_id_here'
    const result = await requests.getTestRequests(templateId)

    expect(axios.get).toHaveBeenCalledWith(`/web/templates/${templateId}/requests`)
    expect(result).toEqual(mockResponse)
  })

  test('createRequest calls the API and returns the response', async () => {
    const mockResponse = {}
    axios.post.mockResolvedValue({ data: mockResponse })

    const templateId = 'your_template_id_here'
    const params = {}
    const result = await requests.createRequest(templateId, params)

    expect(axios.post).toHaveBeenCalledWith(`/web/templates/${templateId}/requests`, params)
    expect(result).toEqual(mockResponse)
  })

  test('restartRequest calls the API and returns the response', async () => {
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })

    const requestId = 'your_request_id_here'
    const result = await requests.restartRequest(requestId)

    expect(axios.get).toHaveBeenCalledWith(`/web/requests/${requestId}/restart`)
    expect(result).toEqual(mockResponse)
  })

})