import React, { useCallback, useEffect, useState } from 'react'
import NotificationTab from './NotificationTab'
import { Drawer, Timeline, Divider } from 'antd'
import useCable from 'components/hooks/useCable'
import Actioncable from 'actioncable'
import apiClient from 'components/lib/api/apiClient'
const messageColor = {
  warning: 'red',
  success: 'green',
  notice: 'orange'
}
const NotificationDrawer = ({userId}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const ws = useCable()
  const cable = Actioncable.createConsumer(ws)
  const [notifications, setNotifications] = useState(null)

  const openDrawer = () => {
    setDrawerOpen(true)
  }

  const closeDrawer = () => {
    setDrawerOpen(false)
  }

  const getNotifications = useCallback(async() => {
    try {
      const response = await apiClient.notification.getNotifications(userId)
      if(response.success){
        setNotifications(response.notices)
      }
    } catch (error) {
      console.error(error)
    }
  }, [userId])

  useEffect(() => {
    if(!userId) return
    const notificationChannel = cable.subscriptions.create(
      { channel: 'NotificationChannel', userId },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log(`Connected to Action Cable with user`)
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log(`Disconnected to Action Cable with user`)
        },
        received(data) {
          setNotifications((prevState) => ([
            data.notice,
            ...prevState,
          ]))
        },
      }
    )

    return () => {
      notificationChannel.unsubscribe()
    }
  }, [cable.subscriptions, userId])

  useEffect(() => {
    const notificationChannel = cable.subscriptions.create(
      { channel: 'NotificationChannel' },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Connected to Action Cable for all notices')
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Disconnected from Action Cable for all notices')
        },
        received(data) {
          setNotifications((prevState) => ([
            data.notice,
            ...prevState,
          ]))
        },
      }
    )

    return () => {
      notificationChannel.unsubscribe()
    }
  }, [cable.subscriptions])

  useEffect(() => {
    if(!notifications && userId) getNotifications()
  },[notifications, getNotifications, userId])

  return (
    <span style={{marginTop: '5px'}}>
      <NotificationTab onClick={openDrawer} count={notifications?.length || 0} />
      {isDrawerOpen && (
        <>
          <Divider />
          <Drawer title="Notification Center" placement="right" onClose={closeDrawer} open={isDrawerOpen}>
            <p>NOTE: Messages disappear after 3 days.</p>
            <Timeline
              mode='left'
              items={
                notifications?.map(({messageType, message, created_at}, i) => {
                  return {
                    key: i,
                    ['data-testid']: `notification-item-${i}`,
                    label: created_at,
                    children: message,
                    color: messageColor[messageType]
                  }
                })
              }
            />
          </Drawer>
        </>
      )}
    </span>
  )
}

export default NotificationDrawer
