import React from 'react'
import Heading from 'components/common/Heading/Heading'
import TemplateType from './TemplateType'
import { Helmet } from 'react-helmet'
const NewTemplatePage = () => {
  return (
    <>
      <Helmet>
        <title>PCP - New Template</title>
      </Helmet>
      <Heading
        title='New Template'
        buttons={[{
            buttonText: 'Back',
            onClick: `/`
          }]}
      />
      <TemplateType />
    </>
  )
}

export default NewTemplatePage