const BillingEndpoint = (axios) => {
  const getBillings = async (template_id) => {
      const { data } = await axios.get(`/web/billings/${template_id}`)
      return data
  }
  return {
    getBillings,
  }
}

export default BillingEndpoint