import useProvidedContext from 'components/hooks/useProvidedContext'
import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react'
import apiClient from 'components/lib/api/apiClient'
export const AccountContext = createContext()
export const AccountActionContext = React.createContext()

export const AccountProvider = ({ children }) => {
  const [accounts, setAccounts] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const actions = useMemo(() => {
    const findAccount = async (accountName) => {
      return accounts.find((account) => account.name === accountName)
    }
    return {
      findAccount,
    }
  }, [accounts])

  const getAccounts = useCallback( async () => {
    try {
      const { accounts } = await apiClient.accounts.getAccounts()
      setAccounts(accounts)
    } catch (error) {
      setAccounts(null)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if(!accounts && isLoading) getAccounts()
  }, [accounts, isLoading, getAccounts])

  return (
    <AccountActionContext.Provider value={actions}>
      <AccountContext.Provider value={{accounts}}>
        {children}
      </AccountContext.Provider>
    </AccountActionContext.Provider>
  )
}

export const useAccounts = () => {
  return useProvidedContext('AccountProvider', AccountContext)
}

export const useAccountActions = () => {
  return useProvidedContext('AccountProvider', AccountActionContext)
}