import { Modal, Table } from 'antd'
import React, { useEffect } from 'react'
import Actioncable from 'actioncable'
import useCable from 'components/hooks/useCable'
const columns = [
  { title: 'Created At', dataIndex: 'created_at' },
  { title: 'Type', dataIndex: 'type' },
  { title: 'State', dataIndex: 'state' },
  { title: 'Host', dataIndex: 'host' },
  { title: 'Tags', dataIndex: 'tags' },
]
const Event = ({openModal, setOpenModal, selectedEvent, setSelectedEvent, requestId}) => {
  const ws = useCable()
  const handleCancel = () => {
    setOpenModal(false)
    setSelectedEvent([])
  }

  useEffect(() => {
    if(!requestId) return
    const cable = Actioncable.createConsumer(ws)
    const requestChannel = cable.subscriptions.create(
      { channel: 'RequestChannel', requestId: `${requestId}-events` },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Connected to Action Cable for Request events')
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Disconnected from Action Cable for Request events')
        },
        received(data) {
          const newEvents = [...selectedEvent, data.event]
          setSelectedEvent(newEvents)
        },
      }
    )

    return () => {
      requestChannel.unsubscribe()
    }
  }, [requestId, selectedEvent, setSelectedEvent, ws])


  return (
    <Modal title="Events" open={openModal} onCancel={handleCancel} footer={null} width='auto'>
      <Table
        columns={columns}
        dataSource={selectedEvent}
        pagination={false}
      />
    </Modal>
  )
}

export default Event