import React, { useState } from 'react'
import { Form, Select, Input, Divider, Button } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useNavigate } from 'react-router-dom'
import { useTemplateActions } from 'components/context/TemplateContext'
const ComposerDetails = ({template}) => {
  const [form] = Form.useForm()
  const [presets] = useState(['480x480', '480x854', '854x480', '512x512', '720x1280', 'Custom'])
  const [imageType] = useState(['.PNG', '.JPG', '.WEBP'])
  const [showDimentions, setDimensions] = useState(false)
  const {updateNewPart} = useTemplateActions()
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const location = useNavigate()
  const handlePresetChange = () => {
    const dimensions = form.getFieldValue('dimensions')
    const destination = form.getFieldValue('destination')
    if(destination === '.WEBP' && dimensions !== '512x512'){
      form.setFieldsValue({destination: '.PNG'})
    }
    if(dimensions === 'Custom') setDimensions(true)
  }

  const handleExtensionChange = () => {
    const destination = form.getFieldValue('destination')
    const dimensions = form.getFieldValue('dimensions')
    if(destination === '.WEBP' && dimensions !== '512x512'){
      form.setFieldsValue({dimensions: '512x512'})
      setDimensions(false)
    }
  }
  const handleNext = async () => {
    const values = await form.validateFields()
    const { parts } = template
    let payload
    if(parts.length === 0){
      const formattedDimensions = values.dimensions === 'Custom' ? `${width}x${height}` : values.dimensions
      payload = {
        destination: values.destination,
        options: {
          dimensions: formattedDimensions
        }
      }
      try {
        const response = await apiClient.parts.createPart(template.id, payload)
        if(response.success){
          updateNewPart(template.id, response.part)
          location(`/templates/${template.id}/composer`)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
  return (
    <>
      <h5 style={{ marginBottom: '28px' }}>Composer Details</h5>
      <Form form={form}>
        <Form.Item name="dimensions">
          <Select
            onChange={handlePresetChange}
            placeholder="Select a Preset"
            optionFilterProp="children"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            {presets.map((ext, idx) => (
              <Select.Option key={idx} value={ext}>
                {ext}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {showDimentions && <div style={{ marginTop: '15px' }}>
          <h5 style={{ marginBottom: '10px' }}>Dimensions</h5>
          <Input placeholder='Width' style={{ marginRight: '10px', width: '15%' }} onChange={(e) => setWidth(e.target.value)}/>
          <Input placeholder='Height' style={{ width: '15%' }} onChange={(e) => setHeight(e.target.value)}/>
        </div>}
        <Divider />
        <Form.Item name="destination">
          <Select
            onChange={handleExtensionChange}
            placeholder="Select a Image Type"
          >
            {imageType.map((ext, idx) => (
              <Select.Option key={idx} value={ext}>
                {ext}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Divider />
      <div className='right'>
        <Button className='button' onClick={handleNext}>Next</Button>
      </div>
    </>
  )
}

export default ComposerDetails