import React, { useState } from 'react'
import { Modal, Form, Input, Divider, Select } from 'antd'
import { useBannerActions } from 'components/context/BannerContext'
import { useAccountActions } from 'components/context/AccountContext'
import apiClient from 'components/lib/api/apiClient'
import { useNavigate } from 'react-router-dom'
const GifComposerForm = ({isModalOpen, template, setIsModalOpen, orderCount = 0}) => {
  const {setBannerMessage, setBannerError } = useBannerActions()
  const { findAccount } = useAccountActions()
  const location = useNavigate()
  const [imageType] = useState(
    [{
      value: '.PNG',
      label: '.PNG',
    },{
      value: '.JPG',
      label: '.JPG',
    }]
  )
  const [width, setWidth] = useState(null)
  const [type, setType] = useState(null)
  const [height, setHeight] = useState(null)
  const [form] = Form.useForm()

  const handleOk = async () => {
    const account = await findAccount(template.account)
    const payload = {
      canvas: {
        dimensions: `${width}x${height}`
      },
      destination: type,
      options: {
        dimensions: `${width}x${height}`
      }
    }
    const newTemplate = {
      account_id: account.id,
      parent_id: template.id,
      required_variables: [],
      tag: `${template.tag}-${orderCount}`,
      kind: 'image',
      source: 'composer',
      version: '1',
      status: 'Active',
      name: `${template.name}-${orderCount}`,
    }
    try {
      const response = await apiClient.templates.createTemplate(newTemplate)
      if (response.success) {
        const partsResponse = await apiClient.parts.createPart(response.template.id, payload)
        if(partsResponse.success){
          setBannerMessage('Composer created')
          location(`/templates/${response.template.id}/composer?parentId=${template.id}`)
        }
      } else {
        setBannerError(response.errors[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleChange = (value) => setType(value)


  return (
    <Modal title="New Composer" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okButtonProps={{ disabled: (!type || !width || !height) }}>
      <Form form={form}>
        <div style={{ marginTop: '15px' }}>
          <h5 style={{ marginBottom: '10px' }}>Dimensions</h5>
          <Input placeholder='Width' style={{ marginRight: '10px', width: '25%' }} onChange={(e) => setWidth(e.target.value)}/>
          <Input placeholder='Height' style={{ width: '25%' }} onChange={(e) => setHeight(e.target.value)}/>
        </div>
        <Divider />
        <Form.Item name="destination">
          <Select
            placeholder="Select a Image Type"
            onChange={handleChange}
            options={imageType}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default GifComposerForm