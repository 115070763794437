import React from 'react'
const useProvidedContext = (providerName, Context) => {
  const context = React.useContext(Context)

  if(context === undefined) {
    throw new Error(`must be used within a <${providerName}>`)
  }

  return context
}

export default useProvidedContext