import { Result, Button } from 'antd'
import { useNavigate } from "react-router-dom"

const ErrorPage = () => {
  const location = useNavigate()
  const goHome = () => {
    location('/')
  }
  return (<Result
    status="500"
    title="500"
    subTitle="Sorry, something went wrong."
    extra={<Button type="primary" onClick={goHome}>Back Home</Button>}
  />)
}

export default ErrorPage