import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
const useQueryParams = () => {
  const location = useLocation()
  return useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true })
  }, [location.search])
}

export default useQueryParams