const UsersEndpoint = (axios) => {
  const getUsers = async () => {
    const { data } = await axios.get('/web/users')
    return data
  }

  const updateUser = async (id, params) => {
    const { data } = await axios.patch(`/web/users/${id}`, params)
    return data
  }

  const createUser = async (params) => {
    const { data } = await axios.post('/web/users', params)
    return data
  }

  const deleteUser = async (id) => {
    const { data } = await axios.delete(`/web/users/${id}`)
    return data
  }

  const getUser = async () => {
    const { data } = await axios.get(`/web/users/me`)
    return data.user
  }
  return {
    getUsers,
    updateUser,
    createUser,
    deleteUser,
    getUser,
  }
}

export default UsersEndpoint