import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import AccountsEndpoint from '../AccountsEndpoint'

const mock = new MockAdapter(axios)

describe('AccountsEndpoint', () => {
  const accounts = AccountsEndpoint(axios)

  afterEach(() => {
    mock.reset()
  })

  it('should get accounts', async () => {
    const mockAccounts = [{ id: 1, name: 'Account 1' }]
    mock.onGet('/web/accounts').reply(200, mockAccounts)

    const result = await accounts.getAccounts()
    expect(result).toEqual(mockAccounts)
  })

  it('should update an account', async () => {
    const accountId = 1
    const updateParams = { name: 'Updated Account' }
    mock.onPatch(`/web/accounts/${accountId}`).reply(200, updateParams)

    const result = await accounts.updateAccount(accountId, updateParams)
    expect(result).toEqual(updateParams)
  })

  it('should create an account', async () => {
    const createParams = { name: 'New Account' }
    mock.onPost('/web/accounts').reply(201, createParams)

    const result = await accounts.createAccount(createParams)
    expect(result).toEqual(createParams)
  })

  it('should upload a font', async () => {
    const accountId = 1
    const uploadParams = { font: 'Font Data' }
    mock.onPost(`/web/accounts/${accountId}/fonts`).reply(200, uploadParams)

    const result = await accounts.uploadFont(accountId, uploadParams)
    expect(result).toEqual(uploadParams)
  })

  it('should delete an account', async () => {
    const accountId = 1
    const deleteResponse = { message: 'Account deleted' }
    mock.onDelete(`/web/accounts/${accountId}`).reply(200, deleteResponse)

    const result = await accounts.deleteAccount(accountId)
    expect(result).toEqual(deleteResponse)
  })

  it('should get an account', async () => {
    const accountId = 1
    const accountInfo = { id: accountId, name: 'Account 1' }
    mock.onGet(`/web/accounts/${accountId}`).reply(200, { account: accountInfo })

    const result = await accounts.getAccount(accountId)
    expect(result).toEqual(accountInfo)
  })
})