import React, { createContext, useState, useMemo } from 'react'
import useProvidedContext from 'components/hooks/useProvidedContext'

export const SearchContext = createContext()
export const SearchActionContext = React.createContext()
const defaultParams = {
  query: '',
  page: 1,
  sortBy: 'created_at',
  sortDirection: 'desc',
  statuses: ['Active'],
  states: ['failed'],
  pageSize: 10,
}
export const SearchProvider = ({ children }) => {
  const [searchParams, setParams] = useState(defaultParams)
  const actions = useMemo(() => {
    const setQuery = (query) => {
      setParams((current) => ({...current, query, page: 1}))
    }
    const setSize = (pageSize) => {
      setParams((current) => ({...current, pageSize, page: 1}))
    }
    const setPage = (page) => {
      setParams((current) => ({...current, page}))
    }
    const setStatuses = (statuses) => {
      setParams((current) => ({...current, statuses: statuses}))
    }
    const setStates = (states) => {
      setParams((current) => ({...current, states}))
    }
    const setSort = (sortBy) => {
      setParams((current) => {
        const sortDirection =
          current.sortBy !== sortBy
          ? 'asc'
          : current.sortDirection === 'asc' ? 'desc' : 'asc'
        return {
          ...current,
          sortBy,
          sortDirection,
        }
      })
    }
    return {
      setPage,
      setQuery,
      setSort,
      setStatuses,
      setStates,
      setSize,
    }
  },[])
  return (
    <SearchActionContext.Provider value={actions}>
      <SearchContext.Provider value={{searchParams}}>
        {children}
      </SearchContext.Provider>
    </SearchActionContext.Provider>
  )
}

export const useSearch = () => {
  return useProvidedContext('SearchProvider', SearchContext)
}

export const useSearchActions = () => {
  return useProvidedContext('SearchProvider', SearchActionContext)
}
