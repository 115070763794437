import { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import usePagination from 'components/hooks/usePagination'
import useDebouncedParams from './useDebouncedParams'
import { useNavigate } from 'react-router'
import { useBannerActions } from 'components/context/BannerContext'
const defaultData = {
  requests: null,
  totalCount: 0,
  loading: true,
}

const useRequests = (searchOptions, template_id) => {
  const [data, setData] = useState(defaultData)
  const {pagination, setPagination} = usePagination()
  const { page, query, sortBy, sortDirection, pageSize, states } = searchOptions
  const searchParams = useDebouncedParams(searchOptions)
  const navigate = useNavigate()
  const { setBannerError } = useBannerActions()
  const reload = useCallback(async () => {
    const params = {
      template_id,
      page,
      query,
      sortBy,
      sortDirection,
      states,
      pageSize,
    }
    try {
      const {success, requests, total} = await apiClient.requests.getRequests(params)
      if(success){
        setData({requests: requests, totalCount: total || 0, loading: false})
        setPagination((currentData) => ({...currentData, total: total}))
      } else {
        setBannerError('Requests not found')
        navigate('/')
      }
    } catch (error) {
      console.error('Error fetching requests:', error)
    }
  }, [template_id, page, query, sortBy, sortDirection, states, pageSize, setPagination, setBannerError, navigate])
  useEffect(() => {
    reload()
  }, [reload, searchParams])
  return {
    ...data,
    setPagination,
    pagination,
    reload,
  }
}

export default useRequests