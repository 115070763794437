import React from 'react'
import { cookieCache } from './cookies'

const useCookie = (cookie) => {
  const [value, setValue] = React.useState(cookie.get())

  React.useEffect(() => {
    const listener = ({name: cookieName, value: newValue}) => {
      if(cookieName === cookie.cookieName) {
        setValue(newValue)
      }
    }
    cookieCache.addChangeListener(listener)

    return () => cookieCache.removeChangeListener(listener)
  }, [cookie.cookieName])

  return [value, cookie.set]
}

export default useCookie