import { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import usePagination from 'components/hooks/usePagination'
import { useNavigate } from 'react-router-dom'
import { useSearchActions } from '../context/SearchContext'
import useDebouncedParams from './useDebouncedParams'
const defaultData = {
  templates: null,
  totalCount: 0,
  loading: true,
}
const useLandingTemplates = (searchOptions) => {
  const [data, setData] = useState(defaultData)
  const {pagination, setPagination} = usePagination()
  const { setQuery } = useSearchActions()
  const location = useNavigate()
  const { page, query, sortBy, sortDirection, statuses, pageSize } = searchOptions
  const searchParams = useDebouncedParams(searchOptions)
  const reload = useCallback(async () => {
    const params = {
      page,
      query,
      sortBy,
      sortDirection,
      statuses,
      pageSize,
    }
    try {
      const {templates, total} = await apiClient.templates.getTemplates(params)
      if(!total && query.length > 20){
        const {template} = await apiClient.templates.getTemplate(params.query)
        if(template) location(`/templates/${template.id}`)
        setQuery('')
      }
      setData({templates: templates, totalCount: total || 0, loading: false})
      setPagination((currentData) => ({...currentData, total: total}))
    } catch (error) {
      console.error('Error fetching templates:', error)
    }
  }, [page, setQuery, query, sortBy, setPagination, sortDirection, statuses, pageSize, location])
  useEffect(() => {
    reload()
  }, [reload, searchParams])
  return {
    ...data,
    setPagination,
    pagination,
    reload,
  }
}

export default useLandingTemplates