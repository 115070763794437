import React from 'react'
import { render, screen } from '@testing-library/react'
import '@testing-library/jest-dom'
import useDestination from '../useDestination'

function TestComponent({kind}) {
  const { destination } = useDestination({ kind })

  return (
    <div>
      {destination.map((option, index) => (
        <div key={index}>{option.label}</div>
      ))}
    </div>
  )
}
describe('useDestination hook', () => {
  it('returns the destination options for video kind', () => {
    render(<TestComponent kind='video' />)

    expect(screen.getByText('.MP4')).toBeInTheDocument()
  })

  it('returns the destination options for composer kind', () => {
    render(<TestComponent kind='composer' />)

    expect(screen.getByText('.PNG')).toBeInTheDocument()
    expect(screen.getByText('.JPG')).toBeInTheDocument()
    expect(screen.getByText('.WEBP')).toBeInTheDocument()
  })

  it('returns the destination options for other kinds', () => {
    render(<TestComponent kind='other' />)

    expect(screen.getByText('.PNG')).toBeInTheDocument()
    expect(screen.getByText('.JPG')).toBeInTheDocument()
    expect(screen.getByText('.GIF')).toBeInTheDocument()
  })
})