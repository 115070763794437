import { CloseOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Input, Select } from 'antd'
import Heading from 'components/common/Heading/Heading'
import { useTemplateActions } from 'components/context/TemplateContext'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'
import { Helmet } from 'react-helmet'
const PersonalizedDetails = () => {
  const { Option } = Select
  const [variables, setVariables] = useState([])
  const params = useParams()
  const { id } = params
  const { findTemplate, updateTemplate } =  useTemplateActions()
  const location = useNavigate()
  const [template, setTemplate] = useState(null)
  const { setBannerError } = useBannerActions()
  const [partNames, setPartNames] = useState({})
  const addVariable = () => {
    setVariables([...variables, { name: '', values: [], frames: [] }])
  }
  const removeVariable = (idx) => {
    const updatedVariables = [...variables]
    updatedVariables.splice(idx, 1)
    setVariables(updatedVariables)
  }

  const addValue = (e, idx) => {
    e.preventDefault()
    const updatedVariables = [...variables]
    const [thisVar] = updatedVariables.splice(idx, 1)
    thisVar.values.push({ value: '' })
    updatedVariables.splice(idx, 0, thisVar)
    setVariables(updatedVariables)
  }

  const removeValue = (idx, valIdx) => {
    const updatedVariables = [...variables]
    const [thisVar] = updatedVariables.splice(idx, 1)
    thisVar.values.splice(valIdx, 1)
    updatedVariables.splice(idx, 0, thisVar)
    setVariables(updatedVariables)
  }

  const buildPayload = () => {
    const toConfig = template.kind === 'video' ? true : false
    let payload = { template: { required_variables: [], data_validators_attributes: [], update_config: toConfig } }
    let updatedVariables = [...variables]
    updatedVariables = updatedVariables.filter((variable) => variable.name.length)
    for (const variable of updatedVariables) {
      if (!variable.frames || variable.frames.length === 0) {
          setBannerError(`Please select a frame for variable: ${variable.name}`)
          return null
      }
  }
    let required_variables = updatedVariables.filter((variable) => !variable.name.includes('?'))
    payload.template.required_variables = required_variables.map((variable) => variable.name)

    payload.template.data_validators_attributes = updatedVariables.map((variable) => {
      let validator = { name: variable.name, values: [] }
      validator.values = variable.values.map((value) => value.value)

      if (validator.values.length) return validator
      return null
    }).filter(Boolean)
    for (const variable of updatedVariables) {
      variable.name = variable.name.replace('?', '')
    }

    return payload
  }

  const handleSubmit = async () => {
    const payload = buildPayload()
    // Check if the payload was successfully built
    if (!payload) return
    try {
      // Prepare to update each part with its associated variables
      const partUpdates = {}
      template.parts.forEach((part) => {
        partUpdates[part.id] = []
      })
      variables.forEach(variable => {
          variable.frames.forEach(partName => {
            const partId = Object.keys(partNames).find(id => partNames[id] === partName)
            if (partId) {
              partUpdates[partId].push(variable.name)
            }
          })
      })

      // Update each part with its associated variables
      for (const [partId, variableNames] of Object.entries(partUpdates)) {
          const partResponse = await apiClient.parts.updatePart(id, partId, { variables: variableNames })
          if (!partResponse.success) {
              throw new Error(`Failed to update part: ${partId}`)
          }
      }

      // Update the template with the payload
      const templateResponse = await apiClient.templates.updateTemplate(id, payload)
      if (templateResponse.success) {
          updateTemplate(id, templateResponse.template)
          location(`/templates/${id}`)
      }

    } catch (error) {
        console.log(error)
        setBannerError('An error occurred while submitting. Please try again.')
    }
  }


  const handleInputChange = (e, index) => {
    const newVariables = [...variables]
    newVariables[index].name = e.target.value
    setVariables(newVariables)
  }

  const handleFrameChange = (selectedFrameNames, index) => {
    const newVariables = [...variables]
    newVariables[index].frames = selectedFrameNames
    setVariables(newVariables)
  }

  useEffect(() => {
    if (!template) return
    const { required_variables, data_validators } = template
    const variables = []
    const names = {}

    template.parts.forEach((part, index) => {
      const partId = part.id
      const partName = `Scene ${index + 1}`
      names[partId] = partName

      const partsVariables = part?.variables || []
      for (const variable of partsVariables) {
        const renamedVar = !required_variables.includes(variable) ? `${variable}?` : variable
        const options = data_validators.find((p) => p.name === renamedVar)
        const object = options?.values.map((str) => ({ value: str }))

        let existingVariable = variables.find((v) => v.name === renamedVar)
        if (!existingVariable) {
          existingVariable = { name: renamedVar, values: object || [], frames: [] }
          variables.push(existingVariable)
        }
        existingVariable.frames.push(partName)
      }
    })

    setPartNames(names)
    if (variables.length > 0) {
      setVariables(variables)
    }
  }, [template, location, setBannerError])

  const foundTemplate = useCallback(async () => {
    try {
      const temp = await findTemplate(id)
      if(temp) setTemplate(temp)
      else {
        setBannerError('Template not found')
        location('/')
      }
    } catch (error) {
      console.error(error)
    }
  }, [findTemplate, location, setBannerError, id])

  useEffect(() => {
    foundTemplate()
  },[foundTemplate])
  return (
    <>
      <Helmet>
        <title>PCP - PersonalizedDetails: {id}</title>
      </Helmet>
      <Heading
        title='Template Variables'
        buttons={[{
            buttonText: 'Back',
            onClick: `/`
          }]}
      />
      <div style={{display: 'flex', justifyContent: 'center'}} >
        <Card style={{width: '60%' }}>
          <h5 style={{ marginBottom: '10px' }}>Personalization Details</h5>
          <p>
            Add the variables that will be substituted for personalized data.
            <br />
            You can also add a list of whitelisted options so only specific values can be used.
          </p>
          <p>If you want to make it an optional variable, simply add a `?` after the variable</p>
          {variables.map((variable, idx) => (
            <div key={idx}>
              <div style={{display: 'flex'}}>
                <Input
                className="form-control"
                type="text"
                name="name"
                id={idx.toString()}
                value={variable.name}
                onChange={(e) => handleInputChange(e, idx)}
                onPressEnter={addVariable}
                placeholder="Variable name. e.g. first_name, plan_code, subscription_level, etc."
              />
                  <Select
                    mode="multiple"
                    value={variable.frames}
                    onChange={(selectedFrameNames) => handleFrameChange(selectedFrameNames, idx)}
                    style={{ width: 200, marginLeft: 8 }}
                  >
                    {Object.entries(partNames).map(([partId, partName], i) => (
                      <Option key={partId} value={partName}>
                        {partName}
                      </Option>
                    ))}
                  </Select>
              <Button
                className="button button-danger button-size-small"
                type="button"
                onClick={() => removeVariable(idx)}
              >
                <CloseOutlined />
              </Button>
            </div>
            {variable.values.map((value, valIdx) => (
              <div key={valIdx} style={{display: 'flex', paddingLeft: '1em', marginTop: '1em'}}>
                <Button className="button button-secondary" style={{width: '20%'}}>
                  Option {valIdx + 1}
                </Button>
                <Input
                  className="form-control"
                  type="text"
                  name="value"
                  id={`${idx}-val-${valIdx}`}
                  value={value.value}
                  onChange={(e) => {
                    const updatedVariables = [...variables]
                    updatedVariables[idx].values[valIdx].value = e.target.value
                    setVariables(updatedVariables)
                  }}
                  onPressEnter={() => addValue(idx)}
                />
                <Button
                  className="button button-danger button-size-small"
                  type="button"
                  onClick={() => removeValue(idx, valIdx)}
                >
                  <CloseOutlined />
                </Button>
              </div>
            ))}
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button type="link" onClick={(e) => addValue(e, idx)} style={{ padding: '5px 0'}}>
                  Add Option List
                </Button>
              </div>
            </div>
          ))}
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              className="button"
              type="button"
              onClick={addVariable}
            >
              Add New Variable
            </Button>
          </div>
          <Divider />
          <div className='right'>
            <Button className='button' onClick={handleSubmit}>Submit</Button>
          </div>
        </Card>
      </div>
    </>
  )
}

export default PersonalizedDetails