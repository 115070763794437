import React from 'react'
import { render, fireEvent, screen } from '@testing-library/react'
import '@testing-library/jest-dom'
import NotificationTab from '../NotificationTab'

describe('NotificationTab component', () => {
  test('renders NotificationTab with count and triggers onClick', () => {
    const onClickMock = jest.fn()

    render(<NotificationTab onClick={onClickMock} count={5} />)

    const notificationTab = screen.getByTestId('notification-tab')
    expect(notificationTab).toBeInTheDocument()
    fireEvent.click(notificationTab)

    expect(onClickMock).toHaveBeenCalled()
  })

  test('renders NotificationTab without count and triggers onClick', () => {
    const onClickMock = jest.fn()

    render(<NotificationTab onClick={onClickMock} count={0} />)

    const notificationTab = screen.getByTestId('notification-tab')
    expect(notificationTab).toBeInTheDocument()
    expect(notificationTab).toHaveAttribute('data-testid', 'notification-tab')
    expect(notificationTab).not.toHaveTextContent('0')

    fireEvent.click(notificationTab)

    expect(onClickMock).toHaveBeenCalled()
  })
})
