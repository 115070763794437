import { useNavigate } from 'react-router-dom'

const useLocation = () => {
  const navigate = useNavigate()
  const location = (url, metaKey = false) => {
    if (metaKey) {
      window.open(url, '_blank')
    } else {
      navigate(url)
    }
  }
  return location
}

export default useLocation