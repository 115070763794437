import React, { useState, useCallback, useEffect } from 'react'
import PartForm from './PartForm'
import useDestination from 'components/hooks/useDestination'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'
import { useTemplateActions } from 'components/context/TemplateContext'
import { useNavigate } from 'react-router-dom'
import Validation from './Validation'
import { Button, Divider, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
const allowedFileTypes = ['application/x-7z-compressed']
const FileDetails = ({ template, setGifFiles = null, setGif = false }) => {
  const {Dragger} = Upload
  const [files, setFiles] = useState(null)
  const [validating, setValidating] = useState(false)
  const [part, setPart] = useState(null)
  const [valid, setValid] = useState(false)
  const {destination} = useDestination(template)
  const {setBannerMessage, setBannerError} = useBannerActions()
  const {updateNewPart} = useTemplateActions()
  const location = useNavigate()

  const startPolling = useCallback(async () => {
    try {
      const response = await apiClient.templates.fileValidation(template.id)
      if(response.status === 200) setValidating(true)
    } catch (error) {
      console.error(error)
    }
  },[template.id])

  const onDropAccepted = useCallback((acceptedFiles, presignedPost, url) => {
    if (acceptedFiles) {
      const formData = new FormData()
      for (let field in presignedPost) {
        formData.append(field, presignedPost[field])
      }

      formData.append('file', acceptedFiles)

      fetch(url, {
        method: 'POST',
        body: formData,
      }).then(() => {
          startPolling()
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [startPolling])


  const fetchPresignedUrl = useCallback(async (acceptedFiles) => {
    const {name, type} = acceptedFiles
    try {
      const result = await apiClient.templates.presignedUrl(template.id, { name, type })
      const presignedPost = result.presigned_post
      const url = presignedPost.url
      delete presignedPost.url
      onDropAccepted(acceptedFiles, presignedPost, url)
    } catch (error) {
      console.error(error)
    }
  },[template.id, onDropAccepted])

  const uploadProps = {
    beforeUpload: (file) => {
      if (!allowedFileTypes.includes(file.type)) {
        setBannerError('Invalid file type. Please upload a 7z')
        return false
      }
      const maxSize = 20 * 1024 * 1024
      if (file.size > maxSize) {
        setBannerError('File is too big, must be less than 20 MB')
        return false
      }
      return true
    },
    customRequest: (e) => fetchPresignedUrl(e.file),
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        setBannerMessage(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        setBannerError(`${info.file.name} file upload failed.`)
      }
     },
     progress: {
       strokeColor: {
         '0%': '#108ee9',
         '100%': '#87d068',
       },
       size: 3,
       format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
     }
  }

  const fileValidated = useCallback((files) => {
    if(!files) return
    const arrayOfObjects = files.map((string) => ({
      label: string,
      value: string,
    }))
    setValidating(false)
    setValid(true)
    setFiles(arrayOfObjects)
  },[])

  const createPart = async () => {
    try {
      const response = await apiClient.parts.createPart(template.id, part)
      if(response.success){
        setBannerMessage('Part has been created!')
        updateNewPart(template.id, response.part)
        location(`/templates/${template.id}/variables`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkFileValidations = useCallback(async () => {
    const {id, version} = template
    if (version) {
      try {
        const {data} = await apiClient.templates.getValidatedFile(id)
        if (!data.file_uploaded) {
          return
        } else if (data.file_uploaded && !data.cached_data?.length) {
          startPolling()
        } else {
          if(setGif) {
            const arrayOfObjects = data.cached_data.map((string) => ({
              label: string,
              value: string,
            }))
            setGifFiles(arrayOfObjects)
          }
          else {
            fileValidated(data.cached_data)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  },[startPolling, setGif, setGifFiles, fileValidated, template])

  useEffect(() => {
    checkFileValidations()
  }, [checkFileValidations])

  return (
    <div>
      {!setGif && <h5>File Details</h5>}

      {!validating && !files && (
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single upload and file must be compressed with 7z.
          </p>
        </Dragger>
      )}

      {validating && (
        <Validation
          template={template}
          setFiles={setGif ? setGifFiles : setFiles}
          setValidating={setValidating}
          eventType="file_validations"
        />
      )}

      {files && (
        <PartForm part={part} files={files} destination={destination} setValid={setValid} setPart={setPart}  />
      )}
      {!setGif && (
        <>
          <Divider />
          <div className='right'>
            <Button disabled={!valid} className='button' onClick={createPart}>Next</Button>
          </div>
        </>)}
    </div>
  )
}

export default FileDetails
