import Cookies from 'universal-cookie'

export const cookieCache = new Cookies()
const cookieDomain = process.env.VPE_HOST === 'localhost' ? 'localhost' : 'sinchengage.com'

if(cookieDomain === null){
  throw new Error('Cookie domain not found!!')
}

const createCookieHelper = (cookieName, maxAgeHours) => {
  const options = {
    path: '/',
    maxAge: 60 * 60 * maxAgeHours
  }
  return {
    cookieName,
    get: () => cookieCache.get(cookieName),
    set: (value) => {
      value == null
        ? cookieCache.remove(cookieName, options)
        : cookieCache.set(cookieName, value, options)
    },
    remove: async () => {
      await cookieCache.remove(cookieName, options)
      return true
    }
  }
}

const cookies = Object.freeze({
  authToken: createCookieHelper('PCPAuthToken', 8)
})

export default cookies