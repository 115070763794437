import UsersEndpoint from '../UsersEndpoint'

describe('UsersEndpoint', () => {
  const axios = {
    get: jest.fn(),
    post: jest.fn(),
    patch: jest.fn(),
    delete: jest.fn(),
  }
  const users = UsersEndpoint(axios)

  afterEach(() => {
    jest.clearAllMocks()
  })

  test('getUsers calls the API and returns the response', async () => {
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })

    const result = await users.getUsers()

    expect(axios.get).toHaveBeenCalledWith('/web/users')
    expect(result).toEqual(mockResponse)
  })

  test('updateUser calls the API and returns the response', async () => {
    const userId = 'your_user_id_here'
    const params = {}
    const mockResponse = {}
    axios.patch.mockResolvedValue({ data: mockResponse })

    const result = await users.updateUser(userId, params)

    expect(axios.patch).toHaveBeenCalledWith(`/web/users/${userId}`, params)
    expect(result).toEqual(mockResponse)
  })

  test('createUser calls the API and returns the response', async () => {
    const params = {}
    const mockResponse = {}
    axios.post.mockResolvedValue({ data: mockResponse })

    const result = await users.createUser(params)

    expect(axios.post).toHaveBeenCalledWith('/web/users', params)
    expect(result).toEqual(mockResponse)
  })

  test('deleteUser calls the API and returns the response', async () => {
    const userId = 'your_user_id_here'
    const mockResponse = {}
    axios.delete.mockResolvedValue({ data: mockResponse })

    const result = await users.deleteUser(userId)

    expect(axios.delete).toHaveBeenCalledWith(`/web/users/${userId}`)
    expect(result).toEqual(mockResponse)
  })

  test('getUser calls the API and returns the user data', async () => {
    const mockResponse = { user: {} }
    axios.get.mockResolvedValue({ data: mockResponse })

    const result = await users.getUser()

    expect(axios.get).toHaveBeenCalledWith('/web/users/me')
    expect(result).toEqual(mockResponse.user)
  })
})