import { useEffect, useState } from 'react'

const useDestination = ({kind}) => {
  const [destination, setDestination] = useState([])
  useEffect(() => {
    if (kind === 'video') {
      setDestination([{
        label: '.MP4',
        value: '.MP4'
      }])
    } 
    else if(kind === 'composer') {
      setDestination([
        {
          label: '.PNG',
          value: '.PNG',
        },
        {
          label: '.JPG',
          value: '.JPG',
        },
        {
          label: '.WEBP',
          value: '.WEBP',
        },
      ])
    } else {
      setDestination([
        {
          label: '.PNG',
          value: '.PNG',
        },
        {
          label: '.JPG',
          value: '.JPG',
        },
        {
          label: '.GIF',
          value: '.GIF',
        },
      ])
    }
  }, [kind])

  return { destination }
}

export default useDestination