
const AccountsEndpoint = (axios) => {
  const getAccounts = async () => {
    const { data } = await axios.get('/web/accounts')
    return data
  }

  const updateAccount = async (id, params) => {
    const { data } = await axios.patch(`/web/accounts/${id}`, params)
    return data
  }

  const createAccount = async (params) => {
    const { data } = await axios.post('/web/accounts', params)
    return data
  }

  const uploadFont = async (id, params) => {
    const { data } = await axios.post(`/web/accounts/${id}/fonts`, params)
    return data
  }

  const uploadRawFont = async (id, params) => {
    const { data } = await axios.post(`/web/accounts/${id}/fonts`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  }

  const deleteAccount = async (id) => {
    const { data } = await axios.delete(`/web/accounts/${id}`)
    return data
  }

  const getAccount = async (id) => {
    const { data } = await axios.get(`/web/accounts/${id}`)
    return data.account
  }
  return {
    getAccounts,
    updateAccount,
    createAccount,
    deleteAccount,
    getAccount,
    uploadFont,
    uploadRawFont,
  }
}

export default AccountsEndpoint