import React from 'react'
import { useBannerActions } from 'components/context/BannerContext'
import { Link } from 'react-router-dom'
import { Image } from 'antd'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import cookies from 'components/util/cookies/url/cookies'
import Banner from 'components/common/Banner'
import { useProfile, useProfileActions } from 'components/context/ProfileContext'
import NotificationDrawer from 'components/common/Notification/NotificationDrawer'
import logo from 'components/sinch-logo.png'

export const Nav = () => {
  const { profile } = useProfile()
  const { updateProfile } = useProfileActions()
  const { setBannerMessage } = useBannerActions()
  const handleLogout = () => {
    const deletedCookie = cookies.authToken.remove()
    if(deletedCookie){
      updateProfile(null)
      setBannerMessage('Logged out!')
      window.location = '/login'
    }
  }
 return (
  <div className='nav'>
    <Link to="/">
      <Image.PreviewGroup>
        <Image src={logo} alt="Sinch Logo" height={30} preview={false} />
      </Image.PreviewGroup>
    </Link>
    <Banner />
    <div className='navbar-expand'>
      {!!profile && profile.role === 'admin' && <Link className='nav-link' to="/users">Users</Link>}
      {!!profile && profile.role === 'admin' && <Link className='nav-link' to="/accounts">Account</Link>}
      {!!profile && profile.role === 'admin' && <Link className='nav-link' to="/queue-status">Queue Status</Link>}
      <Link className='nav-link' to="/request-reporter">Request Reporter</Link>
      <NotificationDrawer className='nav-link' userId={profile?.id} />
      <Link className='nav-link' to="/profile"><UserOutlined /></Link>
      <Link className='nav-link' to="" onClick={handleLogout}><LogoutOutlined /></Link>
    </div>
  </div>
 )
}
export default Nav