import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Descriptions, Input, Table} from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'
import { Helmet } from 'react-helmet'
const columns = [
  {
    title: 'Event ID',
    dataIndex: 'event_id',
    key: 'event_id',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Event Type',
    dataIndex: 'event_type',
    key: 'event_type',
  },
  {
    title: 'Event State',
    dataIndex: 'event_state',
    key: 'event_state',
  },
  {
    title: 'Time Delta',
    dataIndex: 'time_delta',
    key: 'time_delta',
  },
  {
    title: 'Host',
    dataIndex: 'host',
    key: 'host',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
  },
]
const RequesReportersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const requestIds = searchParams.get('requestIds')
  const { Search } = Input
  const location = useNavigate()
  const [dataSource, setDataSoruce] = useState([])
  const {setBannerNotice, setBannerMessage, setBannerError} = useBannerActions()
  const onSearch = useCallback(async (value) => {
    const trimmedValue = value.replace(/\s/g, "")
    try {
      const { report } = await apiClient.requests.search(trimmedValue)
      if(report) {
        setDataSoruce(report)
        if(trimmedValue !== requestIds) {
          setSearchParams({'requestIds': trimmedValue})
        }
      }
      else setBannerNotice('No Reports found!')
    } catch (error) {
      console.log(error)
    }
  }, [setBannerNotice, requestIds, setSearchParams])

  const goToTemplate = (id) => {
    location(`/templates/${id}`)
  }

  const restartRequest = async (id) => {
    try {
      const {html} = await apiClient.requests.restartRequest(id)
      if(html === 'false') setBannerError(`Request: ${id} did not restart!`)
      else setBannerMessage(`Request: ${id} has been restarted!`)
    } catch (error) {
      setBannerError(`Something went wrong check console for error!`)
      console.error(error)
    }
  }

  useEffect(() => {
    if(!requestIds) return
    onSearch(requestIds)
  }, [requestIds, onSearch])

  return (
    <>
      <Helmet>
        <title>PCP - Request Reporter</title>
      </Helmet>
      <Search
        placeholder="Requests, e.g 'id1, id2'"
        allowClear
        enterButton="Search"
        size="large"
        onSearch={onSearch}
      />
      <br />
      <br />
      {dataSource.map((request) => (
        <div key={request.id} >
          <Card>
            <Button style={{float: 'right'}} onClick={() => goToTemplate(request.template.id)}>Template</Button>
            <Descriptions title={request.request_title}>
              {request.restart_id && (
                <Descriptions.Item label="Control">
                  <Button onClick={() => restartRequest(request.restart_id)}>Restart</Button>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Template Name">
                {request.template.name || 'Missing Name'}
              </Descriptions.Item>
              <Descriptions.Item label="Template Tag">
                {request.template.tag || 'Missing Tag'}
              </Descriptions.Item>
              <Descriptions.Item label="Completed at">
                {request.completed_at || 'Incomplete'}
              </Descriptions.Item>
              <Descriptions.Item label="Completed in">
                {request.completed_in || 'Incomplete'}
              </Descriptions.Item>
              <Descriptions.Item label="State">
                {request.state || 'Missing State'}
              </Descriptions.Item>
            </Descriptions>
            <hr />
            <div className="table-responsive">
              <Table dataSource={request.events} columns={columns} />
            </div>
            {request.delayed_by && (
              <>
                <hr />
                <h2>Delayed by Events table</h2>
                <div className="table-responsive">
                  <Table dataSource={request.delayed_by_events} columns={columns} />
                </div>
              </>
            )}
          </Card>
        </div>
      ))}
    </>
  )
}

export default RequesReportersPage