import React from "react"
import App from "./App"
class PersonalizedContentPlatform extends React.Component {
  render () {
    return (
      <App />
    )
  }
}
export default PersonalizedContentPlatform
