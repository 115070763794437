import React from 'react'
import { render, screen, fireEvent } from '@testing-library/react'
import '@testing-library/jest-dom'
import Heading from './Heading'
import { MemoryRouter } from 'react-router-dom'

describe('Heading component', () => {
  test('renders title and buttons', () => {
    const title = 'Test Title'
    const buttons = [
      { buttonText: 'Button 1', onClick: jest.fn() },
      { buttonText: 'Button 2', onClick: jest.fn() },
    ]

    render(
      <MemoryRouter>
        <Heading title={title} buttons={buttons} />
      </MemoryRouter>
    )

    expect(screen.getByText(title)).toBeInTheDocument()

    buttons.forEach((button) => {
      const buttonElement = screen.getByText(button.buttonText)
      expect(buttonElement).toBeInTheDocument()
      fireEvent.click(buttonElement)
      expect(button.onClick).toHaveBeenCalled()
    })
  })

  test('handles disabled buttons', () => {
    const buttons = [
      { buttonText: 'Enabled Button', onClick: jest.fn() },
      { buttonText: 'Disabled Button', onClick: jest.fn(), disabled: true },
    ]

    render(
      <MemoryRouter>
        <Heading title='Test' buttons={buttons} />
      </MemoryRouter>
    )

    const enabledButton = screen.getByText('Enabled Button')
    const disabledButton = screen.getByText('Disabled Button')

    expect(enabledButton).toBeInTheDocument()
    expect(disabledButton).toBeInTheDocument()

    fireEvent.click(enabledButton)
    fireEvent.click(disabledButton)

    expect(buttons[0].onClick).toHaveBeenCalled()
    expect(buttons[1].onClick).not.toHaveBeenCalled()
  })

  test('handles string path for onClick', () => {
    const mockNavigate = jest.fn()
    jest.mock('react-router-dom', () => ({
      ...jest.requireActual('react-router-dom'),
      useNavigate: () => mockNavigate,
    }))

    const buttons = [{ buttonText: 'Go to /path', onClick: '/path' }]

    render(
      <MemoryRouter>
        <Heading title='Test' buttons={buttons} />
      </MemoryRouter>
    )

    const pathButton = screen.getByText('Go to /path')

    expect(pathButton).toBeInTheDocument()
  })
})
