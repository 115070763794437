const AuthenticationEndpoint = (axios) => {
  const logIn = async (params) => {
    const requestData = { session: { ...params } }
    const { data } = await axios.post('/web/sessions', requestData)
    return data.token
  }

  const resetPassword = async (params) => {
    const { data } = await axios.patch('/web/password_resets', params)
    return data
  }

  const forgotPassword = async (params) => {
    const { data } = await axios.post('/web/password_resets', params)
    return data
  }

  return {
    logIn,
    resetPassword,
    forgotPassword,
  }
}

export default AuthenticationEndpoint