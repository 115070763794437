const TemplatesEndpoint = (http) => {
  const getTemplates = async ({
    page,
    query,
    sortBy,
    sortDirection,
    statuses,
    pageSize}) => {
    const { data } = await http.get(`/web/templates?page=${page}&query=${query}&sortBy=${sortBy}&sortDirection=${sortDirection}&statuses=${statuses}&pageSize=${pageSize}`)
    return data
  }

  const updateTemplate = async (templateId, params) => {
    const { data } = await http.patch(`/web/templates/${templateId}`, params)
    return data
  }

  const createTemplate = async (params) => {
    const { data } = await http.post('/web/templates', params)
    return data
  }

  const deleteTemplate = async (id) => {
    const { data } = await http.delete(`/web/templates/${id}`)
    return data
  }

  const archiveTemplate = async (id) => {
    const { data } = await http.patch(`/web/templates/${id}/archive`)
    return data
  }

  const getTemplate = async (id) => {
    const { data } = await http.get(`/web/templates/${id}`)
    return data
  }

  const getChildren = async (id) => {
    const { data } = await http.get(`/web/templates/${id}/get_children`)
    return data
  }

  const updateTemplateDefaults = async (templateId, params) => {
    const { data } = await http.patch(`/web/templates/${templateId}/create_or_edit_template_defaults`, params)
    return data
  }

  const templateConditions = async (templateId, params) => {
    const { data } = await http.patch(`/web/templates/${templateId}/create_or_edit_conditionals`, params)
    return data
  }

  const duplicateTemplate = async (templateId, params) => {
    const { data } = await http.post(`/web/templates/${templateId}/duplicate`, params)
    return data
  }

  const gitValidation = async (templateId, params) => {
    const { data } = await http.post(`/web/templates/${templateId}/git_validations`, params)
    return data
  }

  const fileValidation = async (templateId) => {
    const response = await http.post(`/web/templates/${templateId}/file_validations`, {})
    return response
  }

  const getValidatedFile = async (templateId) => {
    const response = await http.get(`/web/templates/${templateId}/file_validations`)
    return response
  }

  const validating = async (templateId, eventType = 'git_validation') => {
    const { data } = await http.get(`/web/templates/${templateId}/events?type=${eventType}&return_cached_data=1`)
    return data
  }

  const presignedUrl = async (templateId, params) => {
    const { data } = await http.post(`/web/templates/${templateId}/presigned_urls`, params)
    return data
  }

  const searchTemplates = async (searchTerm) => {
    const { data }  = await http.get(`/web/templates?template_search=${searchTerm}`)
    return data
  }

  const updateGitTemplate = async (templateId, version = {}) => {
    const { data } = await http.post(`/web/templates/${templateId}/template_updates`, version)
    return data
  }

  const configureTemplate = async (id) => {
    const { data } = await http.get(`/web/templates/${id}/template_configure`)
    return data
  }

  return {
    archiveTemplate,
    configureTemplate,
    createTemplate,
    deleteTemplate,
    duplicateTemplate,
    fileValidation,
    getChildren,
    getValidatedFile,
    getTemplate,
    getTemplates,
    gitValidation,
    presignedUrl,
    searchTemplates,
    templateConditions,
    updateTemplate,
    updateTemplateDefaults,
    updateGitTemplate,
    validating,
  }
}

export default TemplatesEndpoint