import React from 'react'
import { render, waitFor, screen } from '@testing-library/react'
import '@testing-library/jest-dom'
import apiClient from 'components/lib/api/apiClient'
import useLandingTemplates from '../useLandingTemplates'
import { SearchProvider } from '../../context/SearchContext'
import { MemoryRouter } from 'react-router-dom'

jest.mock('components/lib/api/apiClient')

const searchOptions = {
  page: 1,
  query: 'example',
  sortBy: 'name',
  sortDirection: 'asc',
  statuses: ['active'],
  pageSize: 10,
}

const mockApiResponse = {
  templates: [{ id: 1, name: 'Template 1' }],
  total: 1,
}

beforeEach(() => {
  apiClient.templates = {
    getTemplates: jest.fn(() => {
      return Promise.resolve(mockApiResponse)
    })
  }
})

const renderComponent = () => {
  const HookWrapper = () => {
    const data = useLandingTemplates(searchOptions)

    return (
      <div>
        <div data-testid="templates">{data.templates ? JSON.stringify(data.templates) : 'null'}</div>
        <div data-testid="totalCount">{data.totalCount}</div>
        <div data-testid="loading">{data.loading ? 'true' : 'false'}</div>
      </div>
    )
  }

  return render(
    <MemoryRouter>
      <SearchProvider>
        <HookWrapper />
      </SearchProvider>
    </MemoryRouter>
  )
}

test('fetches and displays templates', async () => {
  renderComponent()

  await waitFor(() => {
    expect(screen.getByTestId('templates')).toHaveTextContent(JSON.stringify(mockApiResponse.templates))
  })
  expect(screen.getByTestId('totalCount')).toHaveTextContent('1')
  expect(screen.getByTestId('loading')).toHaveTextContent('false')
})
