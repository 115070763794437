import { Card, List } from 'antd'
import React from 'react'

const DataValidator = ({dataValidators}) => {
  return (
    <Card style={{width: '100%'}}>
      <h2>Data Validator</h2>
      <List
        dataSource={dataValidators}
        renderItem={(data) => (
          <List.Item
            className="cursor-pointer list"
          >
            {data.name} required: [{data.values.join(', ')}]
          </List.Item>
        )}
      />
    </Card>
  )
}

export default DataValidator