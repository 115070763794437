import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input, Select, Button, Card } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useProfile, useProfileActions } from 'components/context/ProfileContext'
import { useBannerActions } from 'components/context/BannerContext'
import { Helmet } from 'react-helmet'
const { Option } = Select

const UserForm = () => {
  const { profile } = useProfile()
  const { updateProfile } = useProfileActions()
  const [form] = Form.useForm()
  const [accounts, setAccounts] = useState([])
  const { setBannerMessage, setBannerError } = useBannerActions()
  const fetchData = useCallback( async () => {
    try {
      const response_accounts = await apiClient.accounts.getAccounts()
      setAccounts(response_accounts.accounts)
    } catch (error) {
      console.log('Failed to fetch user data:', error)
    }
  }, [])
  const onFinish = async () => {
    const values = await form.validateFields()
    if (values.password === undefined) {
      delete values.password
    }
    try {
      const response = await apiClient.users.updateUser(profile.id, values)
      if(response.success){
        updateProfile(response.user)
        setBannerMessage('User has been updated!')
      } else setBannerError('Unable to update user')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
    if(profile) {
      const remapUser = {
        ...profile,
        account_ids: profile.accounts
      }
      form.setFieldsValue(remapUser)
    }
  },[fetchData, form, profile])

  return (
    <div style={{display: 'flex', justifyContent: 'center'}} >
      <Helmet>
        <title>PCP - Profile</title>
      </Helmet>
      <Card style={{ width:'980px' }}>
        <h5 style={{ marginBottom: '28px'}}>Edit Profile</h5>

        <Form
          name="user_form"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="First Name"
            name="first_name"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="last_name"
          >
            <Input />
          </Form.Item>

          {profile?.role === 'admin' && (
            <>
              <Form.Item name="account_ids" label="Accounts" required>
                <Select
                  placeholder="Select Account(s)"
                  mode="multiple"
                  options={accounts.map((account, index) => (
                    {
                      key: index,
                      label: account.name,
                      value: account.id,
                    }
                  ))}
                />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: 'Please select a role!' }]}
              >
                <Select>
                  {['admin', 'user'].map((role) => (
                    <Option key={role} value={role}>
                      {role}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item className='footer-btn'>
            <Button className='button' type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default UserForm
