import React from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { useBannerActions } from 'components/context/BannerContext'
import apiClient from 'components/lib/api/apiClient'
const UserForm = ({isModalOpen, accounts, setUsers, setIsModalOpen, form, selectedUser, users}) => {
  const {setBannerMessage, setBannerError } = useBannerActions()

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      if(selectedUser){
        const response = await apiClient.users.updateUser(selectedUser.id, values)
        const updatedUsers = users.map((user) => {
          if (user.id === selectedUser.id) {
            return response.user
          }
          return user
        })
        setUsers(updatedUsers)
      } else {
        const { user } = await apiClient.users.createUser(values)
        setUsers(prevUsers => [...prevUsers, user])
      }
      setBannerMessage(selectedUser ? 'User has been updated!' : 'New user has been created!')
      form.resetFields()
      setIsModalOpen(false)
    } catch (error) {
      if(selectedUser){
        setBannerError('Failed to create new user')
        console.error('Failed to create user', error);
      }
      else {
        setBannerError('Failed to update user')
        console.error('Failed to update user', error);
      }
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }
  return (
    <Modal title={selectedUser ? 'Edit User' : 'New User'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical" >
        <Form.Item name="email" required>
          <Input placeholder="Email Address" />
        </Form.Item>
        <Form.Item name="password" required>
          <Input type='password' placeholder="Password" />
        </Form.Item>
        <Form.Item name="first_name" required>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="last_name" required>
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item name="account_ids" required>
          <Select
            placeholder="Select Account"
            mode="multiple"
            options={accounts.map((account, index) => (
              {
                key: index,
                label: account.name,
                value: account.id,
                desc: account.name,
              }
            ))}
          />
        </Form.Item>
        <Form.Item name="role" required>
          <Select placeholder="Select Role">
            <Select.Option value="user">User</Select.Option>
            <Select.Option value="admin">Admin</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UserForm