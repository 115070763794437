import React, { createContext, useState, useCallback, useEffect, useMemo } from 'react'
import useProvidedContext from 'components/hooks/useProvidedContext'
import apiClient from 'components/lib/api/apiClient'
import { useNavigate } from 'react-router-dom'
import cookies from 'components/util/cookies/url/cookies'

export const ProfileContext = createContext()
export const ProfileActionContext = React.createContext()

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const location = useNavigate()
  const actions = useMemo(() => {
    const updateProfile = (updatedProfile) => setProfile(updatedProfile)
    return{
      updateProfile
    }
  },[])

  const getUserInfo = useCallback( async () => {
    try {
      const data = await apiClient.users.getUser()
      setProfile(data)
    } catch (error) {
      setProfile(null)
      cookies.authToken.remove()
      location('/login')
    } finally {
      setIsLoading(false)
    }
  }, [location])

  useEffect(() => {
    if(!profile && isLoading) getUserInfo()
  }, [profile, isLoading, getUserInfo])
  return (
    <ProfileActionContext.Provider value={actions}>
      <ProfileContext.Provider value={{profile, isLoading}}>
        {children}
      </ProfileContext.Provider>
    </ProfileActionContext.Provider>
  )
}

export const useProfile = () => {
  return useProvidedContext('ProfileProvider', ProfileContext)
}

export const useProfileActions = () => {
  return useProvidedContext('ProfileProvider', ProfileActionContext)
}
