import React from 'react'
import { render, screen } from '@testing-library/react'
import '@testing-library/jest-dom'
import { Banner } from './Banner'
import {BannerProvider, useBannerMessage} from '../../context/BannerContext'

jest.mock('../../context/BannerContext', () => ({
  ...jest.requireActual('../../context/BannerContext'),
  useBannerMessage: jest.fn(),
}))

describe('Banner component', () => {
  test('renders banner with message and type', () => {
   useBannerMessage.mockReturnValue({
      type: 'success',
      message: 'Banner message',
    })

    render(
      <BannerProvider>
        <Banner />
      </BannerProvider>
    )

    expect(screen.getByText('Banner message')).toBeInTheDocument()
    expect(screen.getByRole('alert')).toHaveClass('ant-alert-success')
  })

  test('does not render banner when no message and type', () => {
   useBannerMessage.mockReturnValue({
      type: null,
      message: null,
    })

    render(
      <BannerProvider>
        <Banner />
      </BannerProvider>
    )

    expect(screen.queryByRole('alert')).toBeNull()
  })
})
