import React, { createContext, useState, useMemo } from 'react'
import useProvidedContext from 'components/hooks/useProvidedContext'
export const VariableContext = createContext()
export const VariableActionContext = React.createContext()

export const VariableProvider = ({ children }) => {
  const [variables, setVariables] = useState([])

  const actions = useMemo(() => {
    const updateVariable = (newVars) => {
      const processedVars = newVars.map((variable) => {
        // Remove {{ and }} and trailing ?
        return variable.replace(/\{\{|\}\}|\?/g, '')
      }).filter(Boolean)
      const newSet = new Set([...variables, ...processedVars])
      setVariables([...newSet])
    }
    return {
      updateVariable
    }
  },[variables])

  return (
    <VariableActionContext.Provider value={actions}>
      <VariableContext.Provider value={{exportVars: variables}}>
        {children}
      </VariableContext.Provider>
    </VariableActionContext.Provider>
  )
}

export const useVariables = () => {
  return useProvidedContext('VariableProvider', VariableContext)
}

export const useVariableActions = () => {
  return useProvidedContext('VariableProvider', VariableActionContext)
}
