import React from 'react'
import { render, screen, fireEvent, waitFor } from '@testing-library/react'
import AccountForm from '../AccountForm'
import apiClient from 'components/lib/api/apiClient'
import '@testing-library/jest-dom'

jest.mock('components/lib/api/apiClient', () => ({
  accounts: {
    createAccount: jest.fn(() => Promise.resolve({ account: { _id: '123', name: 'Test Account', id_rsa: '456', uuid: '789' } })),
    updateAccount: jest.fn(() => Promise.resolve({ account: { _id: '123', name: 'Updated Account', id_rsa: '789', uuid: '456' } })),
  },
}))

jest.mock('components/context/BannerContext', () => ({
  useBannerActions: () => ({ setBannerMessage: jest.fn(), setBannerError: jest.fn() }),
}))

describe('AccountForm Component', () => {
  test('renders AccountForm for creating a new account', async () => {
    render(<AccountForm isModalOpen={true} accounts={[]} setIsModalOpen={jest.fn()} setAccounts={jest.fn()} />)

    expect(screen.getByText('New Account')).toBeInTheDocument()
    expect(screen.getByPlaceholderText('Name')).toBeInTheDocument()
    expect(screen.getByPlaceholderText('ID RSA')).toBeInTheDocument()
    expect(screen.getByPlaceholderText('Account UUID')).toBeInTheDocument()
  })

  test('renders AccountForm for updating an existing account', async () => {
    const selectedAccount = { _id: '123', name: 'Existing Account', id_rsa: '456', uuid: '789' }
    render(<AccountForm isModalOpen={true} selectedAccount={selectedAccount} accounts={[]} setIsModalOpen={jest.fn()} setAccounts={jest.fn()} />)

    expect(screen.getByDisplayValue('Existing Account')).toBeInTheDocument()
    expect(screen.getByPlaceholderText('Name')).toHaveValue('Existing Account')
    expect(screen.getByPlaceholderText('ID RSA')).toHaveValue('456')
    expect(screen.getByPlaceholderText('Account UUID')).toHaveValue('789')
  })

  test('calls createAccount API when creating a new account', async () => {
    render(<AccountForm isModalOpen={true} accounts={[]} setIsModalOpen={jest.fn()} setAccounts={jest.fn()} />)

    fireEvent.change(screen.getByPlaceholderText('Name'), { target: { value: 'Test Account' } })
    fireEvent.change(screen.getByPlaceholderText('ID RSA'), { target: { value: '123' } })
    fireEvent.change(screen.getByPlaceholderText('Account UUID'), { target: { value: '456' } })

    fireEvent.click(screen.getByText('OK'))

    await waitFor(() => {
      expect(apiClient.accounts.createAccount).toHaveBeenCalledWith({
        name: 'Test Account',
        id_rsa: '123',
        uuid: '456',
      })
    })
  })

  test('calls updateAccount API when updating an existing account', async () => {
    const selectedAccount = { _id: '123', name: 'Existing Account', id_rsa: '456', uuid: '789' }
    render(<AccountForm isModalOpen={true} selectedAccount={selectedAccount} accounts={[]} setIsModalOpen={jest.fn()} setAccounts={jest.fn()} />)

    fireEvent.change(screen.getByPlaceholderText('Name'), { target: { value: 'Updated Account' } })
    fireEvent.change(screen.getByPlaceholderText('ID RSA'), { target: { value: '789' } })
    fireEvent.change(screen.getByPlaceholderText('Account UUID'), { target: { value: '456' } })

    fireEvent.click(screen.getByText('OK'))

    await waitFor(() => {
      expect(apiClient.accounts.updateAccount).toHaveBeenCalledWith('123', {
        name: 'Updated Account',
        id_rsa: '789',
        uuid: '456',
      })
    })
  })
})
